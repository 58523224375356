import styled, { css } from 'styled-components';
import Icon from '@/components/Icon';

export const StyledAviosIcon = styled(Icon).withConfig({
  shouldForwardProp: prop => prop === 'iconName',
})<{ $hasStroke: boolean }>`
  stroke: ${props =>
    props.$hasStroke ? props.theme.header.userMenu.text.color : 'none'};
  margin-right: 0.4rem;
`;

export const UserInfoPopup = styled.div`
  z-index: 1;
  position: absolute;
  // We want the user info popup to go over the header
  // by a single rem unit
  top: calc(100% - 1rem);
  right: ${props => props.theme.header.userMenu.popupRightPosition};
  background-color: white;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  border-radius: 6px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
  width: 18rem;
`;

export const BalanceContainer = styled.div`
  text-align: right;
`;

export const LogoutContainer = styled.div`
  background: ${props => props.theme.header.userMenu.logout.background};
  padding: 0.6rem 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  ${({ theme }) =>
    theme.header.userMenu.logout.hoverDecoration &&
    css`
      &:hover {
        text-decoration: ${theme.header.userMenu.logout.hoverDecoration};
      }
    `}
`;

export const MembershipNumberContainer = styled.div`
  text-align: right;
  margin-bottom: 1.6rem;
`;

export const MyAccountContainer = styled.div`
  background: ${props => props.theme.header.userMenu.myAccount.background};
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;

  ${({ theme }) =>
    theme.header.userMenu.logout.hoverDecoration &&
    css`
      &:hover {
        text-decoration: ${theme.header.userMenu.myAccount.hoverDecoration};
      }
    `}
`;

export const UserPopupInfoTitle = styled.p`
  color: ${props => props.theme.header.userMenu.title.color};
  text-align: right;
  font-family: ${props => props.theme.header.userMenu.title.fontFamily};
  font-size: ${props => props.theme.header.userMenu.title.fontSize};
  font-style: normal;
  font-weight: 400;
  line-height: ${props => props.theme.header.userMenu.title.lineHeight};
  letter-spacing: ${props => props.theme.header.userMenu.title.letterSpacing};
`;

export const MembershipNumber = styled.p`
  color: ${props => props.theme.header.userMenu.text.color};
  text-align: right;
  font-family: ${props => props.theme.header.userMenu.text.fontFamily};
  font-size: ${props => props.theme.header.userMenu.text.fontSize};
  font-style: normal;
  font-weight: ${props => props.theme.header.userMenu.text.fontWeight};
  line-height: ${props => props.theme.header.userMenu.text.lineHeight};
`;

export const Balance = styled.span<{ $isError?: boolean }>`
  color: ${props =>
    props.$isError
      ? props.theme.header.userMenu.errorColor
      : props.theme.header.userMenu.aviosBalance.color};
  text-align: right;
  font-family: ${props => props.theme.header.userMenu.aviosBalance.fontFamily};
  font-size: ${props => props.theme.header.userMenu.aviosBalance.fontSize};
  font-style: normal;
  font-weight: ${props => props.theme.header.userMenu.aviosBalance.fontWeight};
  line-height: ${props => props.theme.header.userMenu.aviosBalance.lineHeight};
`;

export const UserInfoPopupContainer = styled.div`
  padding: 2rem 2rem 1rem 2rem;
`;

export const MyAccountIcon = styled(Icon)`
  stroke: ${props => props.theme.header.userMenu.link.color};
  margin: 0.6rem 0.8rem 0.6rem 1rem;
  stroke-width: 0.12rem;
`;

export const MyAccountText = styled.p`
  margin: 0.6rem 0;
  color: ${props => props.theme.header.userMenu.link.color};
  font-size: 1.6rem;
  font-style: normal;
  font-weight: ${props => props.theme.header.userMenu.link.fontWeight};
  line-height: 2.4rem;
  font-family: ${props => props.theme.header.userMenu.link.fontFamily};
  letter-spacing: ${props => props.theme.header.userMenu.link.letterSpacing};
`;

export const MyAccountLink = styled.a`
  margin: 0.6rem 0;
  color: ${props => props.theme.header.userMenu.link.color};
  font-size: 1.6rem;
  font-style: normal;
  text-decoration: none;
  font-weight: ${props => props.theme.header.userMenu.link.fontWeight};
  line-height: 2.4rem;
  font-family: ${props => props.theme.header.userMenu.link.fontFamily};
  letter-spacing: ${props => props.theme.header.userMenu.link.letterSpacing};

  &:hover {
    color: ${props => props.theme.header.userMenu.link.color};
    text-decoration: none;
  }
`;

export const ExpiryDate = styled.p<{ $isError: boolean }>`
  color: ${props =>
    props.$isError
      ? props.theme.header.userMenu.errorColor
      : props.theme.header.userMenu.expiryDateColor};
  text-align: right;
  font-family: ${props => props.theme.header.fontFamily};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8rem;
`;

export const BalanceRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
