export type Props = {
  id?: string;
  noScript?: boolean;
};

const GoogleTagManager = ({ id, noScript }: Props) => {
  if (noScript) {
    return (
      <noscript>
        <iframe
          title="www.googletagmanager.com"
          className="iframe-hidden"
          src={`https://www.googletagmanager.com/ns.html?id=${id}`}
          height="0"
          width="0"
        />
      </noscript>
    );
  }
  return (
    // eslint-disable-next-line @next/next/next-script-for-ga
    <script
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push(
          {'gtm.start': new Date().getTime(),event:'gtm.js'}
          );var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${id}');`,
      }}
    />
  );
};

export default GoogleTagManager;
