import type { DataLayer } from '@/utils/dataLayer';

interface Props {
  data: DataLayer;
}

export default function DataLayerScript({ data }: Readonly<Props>) {
  return (
    <script
      dangerouslySetInnerHTML={{
        __html: `
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(${JSON.stringify(data)});
  `,
      }}
    />
  );
}
