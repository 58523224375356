import { useEffect, useMemo, type ReactNode } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { useUser } from '@auth0/nextjs-auth0/client';
import Head from 'next/head';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import Sidebar from '@/components/Sidebar';
import SubHeader from '@/components/SubHeader';
import { useGlobalContext } from '@/components/GlobalProvider';
import { getConvertedProgrammeId, type DataLayer } from '@/utils/dataLayer';
import {
  MainLayoutContainer,
  PageLayoutContainer,
  ContentContainer,
} from './styledComponents';
import DataLayerScript from '../DataLayerScript';
import GoogleTagManager from '../GoogleTagManager/GoogleTagManager';
import { GTM_TRACKING_IDS } from '../../utils/constants';

type LayoutProps = {
  children: ReactNode;
  singleAviosBalanceExpiryDate?: string;
  dataLayerInfo: DataLayer;
};

const MainLayout = ({
  children,
  singleAviosBalanceExpiryDate,
  dataLayerInfo,
}: LayoutProps) => {
  const router = useRouter();
  const userContext = useUser();
  const { t } = useTranslation('common');
  const { singleAviosBalance } = useGlobalContext();

  useEffect(() => {
    // @ts-expect-error: Window does not have dataLayer natively.
    if (!userContext.isLoading && window && window.dataLayer) {
      // @ts-expect-error: Window does not have dataLayer natively.
      window.dataLayer.push(dataLayerInfo);
    }
  }, [router.pathname]);

  const gtmId = useMemo(() => {
    const mappedProgrammeId = getConvertedProgrammeId(
      dataLayerInfo.programmeCode,
    );
    return GTM_TRACKING_IDS[mappedProgrammeId];
  }, [dataLayerInfo]);

  return (
    <>
      <Head>
        <meta name="description" content={t('pageDescription')} />
      </Head>
      <MainLayoutContainer>
        <DataLayerScript data={dataLayerInfo} />
        <GoogleTagManager id={gtmId} />
        <Header
          singleAviosBalance={singleAviosBalance}
          singleAviosBalanceExpiryDate={singleAviosBalanceExpiryDate}
        />
        <SubHeader />
        <ContentContainer>
          <Sidebar />
          <PageLayoutContainer>{children}</PageLayoutContainer>
        </ContentContainer>
        <Footer />
      </MainLayoutContainer>
    </>
  );
};

export default MainLayout;
