import type { SVGProps } from 'react';

type IconProps = {
  iconName: string;
} & SVGProps<SVGElement>;

export default function Icon({ iconName, ...svgProps }: IconProps) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const IconSVG = require(`./icons/${iconName}.svg`).default;
    return <IconSVG {...svgProps} />;
  } catch (error) {
    return <></>;
  }
}
