import { css } from 'styled-components';
import type { DefaultTheme } from 'styled-components';
import { ATRP } from '@/utils/constants';
import type { Font, Palette } from '../types';
import VuelingPilcrow from '../fonts/vuelingPilcrow';
import SourceSansPro from '../fonts/sourceSansPro';

export const font: Font = {
  primary: 'Vueling Pilcrow',
  secondary: 'Source Sans Pro',
};

export const fontFamilies = css`
  ${VuelingPilcrow};
  ${SourceSansPro};

  body {
    font-family: ${font.primary}, ${font.secondary}, sans-serif;
  }
`;

export const primaryPalette: Palette = {
  yellow: {
    p100: '#FFFCF0',
    p200: '#FEF3C6',
    p300: '#FFEA99',
    p400: '#FFDB4D',
    p500: '#FFCC00',
    p600: '#E5B700',
    p700: '#BB9600',
    p800: '#907600',
    p900: '#665500',
  },
  grey: {
    p0: '#FFFFFF',
    p100: '#F8F8F8',
    p200: '#D8D8D8',
    p300: '#B8B8B8',
    p400: '#999999',
    p500: '#787878',
    p600: '#4D4D4D',
    p700: '#414141',
    p800: '#333333',
    p900: '#282828',
    p1: '#000000',
  },
};

export const secondaryPallete: Palette = {
  cyan: {
    s100: '#DBF1F0',
    s500: '#0DA398',
  },
  red: {
    s100: '#FEE2E1',
    s600: '#CC312F',
  },
};

export const highlight: Palette = {
  yellow: '#E5B700',
};

const theme: DefaultTheme = {
  name: ATRP,
  button: {
    background: '#FFF000',
    borderColor: '#FFF000',
    borderRadius: '100px',
    color: primaryPalette.grey.p800,
    disabled: primaryPalette.yellow.p300,
    fontFamily: font.primary,
    highlight: highlight.yellow,
    boxShadow: 'none',
    textTransform: 'uppercase',
  },
  card: {
    name: {
      fontFamily: font.primary,
      color: primaryPalette.grey.p800,
      letterSpacing: '0',
      textTransform: 'uppercase',
    },
    userId: {
      fontFamily: font.secondary,
      color: '#666',
      fontSize: '1.4rem',
      lineHeight: '2.1rem',
      letterSpacing: '0',
    },
    balance: {
      wrapperBackground: '#fff',
      color: primaryPalette.grey.p800,
      fontFamily: font.primary,
      fontSize: '2rem',
      border: `1px solid ${primaryPalette.grey.p300}`,
    },
    unlink: {
      color: '#666666',
      fontFamily: font.primary,
      fontSize: '1.4rem',
      fontWeight: '600',
      lineHeight: '2.1rem',
      unlinkStroke: '#666666',
      unlinkHoverStroke: '#FF4A22',
    },
    iconStroke: primaryPalette.grey.p800,
  },
  login: {
    containerBackground: primaryPalette.grey.p100,
    margin: '4rem 0 12rem 2.4rem',
    title: {
      color: primaryPalette.grey.p800,
      fontFamily: font.primary,
      fontSize: '4.8rem',
      fontWeight: '500',
      lineHeight: '6rem',
      textTransform: 'uppercase',
    },
    subtitle: {
      color: primaryPalette.grey.p800,
      fontFamily: font.primary,
      fontSize: '2rem',
      fontWeight: '500',
      lineHeight: '3rem',
      textTransform: 'uppercase',
    },
    description: {
      fontFamily: font.secondary,
      fontSize: '1.6rem',
      color: primaryPalette.grey.p800,
      lineHeight: '2.4rem',
      fontWeight: '400',
    },
    button: {
      fontWeight: '500',
      alignment: 'left',
    },
    row: {
      gap: '6rem',
      tabletGap: '4rem',
      marginRight: '0',
    },
    partners: {
      mobileMargin: '0 1.9rem 4rem 0',
    },
  },
  loginPartners: {
    title: {
      fontFamily: font.secondary,
      color: '#666',
      fontWeight: '400',
      lineHeight: '1.8rem',
      fontSize: '1.2rem',
    },
  },
  logout: {
    fontFamily: font.secondary,
    color: primaryPalette.grey.p500,
    fontSize: '1.4rem',
  },
  sidebar: {
    menuBackground: primaryPalette.grey.p100,
    listItem: {
      color: primaryPalette.grey.p800,
      colorHighlight: primaryPalette.grey.p800,
      fontFamily: font.primary,
      fontSize: '1.6rem',
      textTransform: 'uppercase',
      fontWeight: '500',
      lineHeight: '2.4rem',
    },
    link: {
      colorHover: primaryPalette.yellow.p500,
    },
  },
  singleAviosBalance: {
    label: {
      fontFamily: font.secondary,
      fontSize: '1.6rem',
      fontWeight: '400',
      lineHeight: '2.4rem',
      textTransform: 'none',
      letterSpacing: '0',
      mobile: {
        fontSize: '1.4rem',
        lineHeight: '2.1rem',
      },
    },
    balance: {
      fontFamily: font.primary,
      fontSize: '4.8rem',
      fontWeight: '500',
      lineHeight: '6rem',
      mobile: {
        fontSize: '4rem',
        lineHeight: '5rem',
      },
    },
    contentGap: '0',
  },
  spinner: {
    labelColor: primaryPalette.grey.p500,
    borderColor: primaryPalette.grey.p100,
    borderTopColor: primaryPalette.yellow.p500,
  },
  subHeader: {
    borderColor: '#E8E8E8',
    titleColor: '#333',
    fontFamily: font.primary,
    fontWeight: '500',
    fontSize: '2.8rem',
    margin: '2.6rem 0 0',
    titlePadding: '0 0 1.6rem',
    textTransform: 'uppercase',
    lineHeight: '4.2rem',
    mobile: {
      fontSize: '2rem',
      lineHeight: '3rem',
    },
  },
  tooltip: {
    fontFamily: font.secondary,
    color: '#666',
    fontSize: '1.4rem',
    title: {
      fontFamily: font.primary,
      fontWeight: '500',
      color: primaryPalette.grey.p800,
      fontSize: '1.4rem',
      letterSpacing: '0',
      textTransform: 'uppercase',
    },
  },
  walletView: {
    containerBackground: primaryPalette.grey.p100,
    header: {
      fontFamily: font.primary,
      color: primaryPalette.grey.p800,
      fontSize: '2.8rem',
      fontWeight: '500',
      lineHeight: '4.2rem',
      textTransform: 'uppercase',
    },
    subtitle: {
      fontFamily: font.secondary,
      color: '#666',
      fontSize: '1.6rem',
      fontWeight: '400',
      lineHeight: '2.4rem',
      mobile: {
        fontSize: '1.4rem',
        lineHeight: '2.1rem',
      },
    },
    rules: {
      fontWeight: '600',
    },
    latestTransactions: {
      headline: {
        fontFamily: font.primary,
        color: primaryPalette.grey.p800,
        fontWeight: '500',
        fontSize: '1.4rem',
        letterSpacing: '0rem',
        mobile: {
          fontSize: '1.4rem',
        },
      },
      extraAction: {
        fontFamily: font.secondary,
        color: '#00A7A6',
        fontSize: '1.4rem',
        mobile: {
          fontSize: '1.4rem',
        },
      },
      description: {
        fontFamily: font.secondary,
        color: primaryPalette.grey.p800,
        fontWeight: '400',
        fontSize: '1.6rem',
      },
      amount: {
        fontFamily: font.primary,
        color: primaryPalette.grey.p800,
        fontSize: '2rem',
        fontWeight: '500',
        lineHeight: '3rem',
      },
      emptyTransactions: {
        color: primaryPalette.grey.p800,
        fontSize: '1.6rem',
        fontFamily: font.secondary,
      },
      errorTransactions: {
        color: '#D82100',
        fontFamily: font.secondary,
        fontSize: '1.6rem',
        lineHeight: '2.4rem',
      },
    },
  },
  toaster: {
    error: {
      background: secondaryPallete.red.s100,
      color: secondaryPallete.red.s600,
      borderLeft: `4px solid ${secondaryPallete.red.s600}`,
    },
    success: {
      background: secondaryPallete.cyan.s100,
      color: secondaryPallete.cyan.s500,
      borderLeft: `4px solid ${secondaryPallete.cyan.s500}`,
    },
    pending: {
      background: '#FFB13D',
      color: '#A36200',
      borderLeft: '4px solid #FFB13D',
    },
  },
  popup: {
    confirmation: {
      title: {
        fontFamily: font.primary,
        textTransform: 'uppercase',
      },
      description: {
        fontFamily: font.secondary,
        color: '#666',
      },
      cancelButton: {
        borderColor: primaryPalette.grey.p800,
        color: primaryPalette.grey.p800,
        backgroundColor: '#fff',
        borderWidth: '2px',
      },
      successButton: {
        textTransform: 'uppercase',
      },
    },
    link: {
      fontFamily: font.secondary,
    },
    unlinkIconStrike: '#D82100',
  },
  latestTransactions: {
    containerBackground: primaryPalette.grey.p100,
    header: {
      fontFamily: font.primary,
      color: primaryPalette.grey.p900,
      fontSize: '3.2rem',
      fontWeight: '300',
    },
    filterTitle: {
      fontFamily: font.primary,
      color: primaryPalette.grey.p800,
      fontWeight: '500',
      fontSize: '1.4rem',
      lineHeight: '2.1rem',
    },
    subtitle: {
      fontFamily: font.secondary,
      color: '#666',
      fontSize: '1.6rem',
      fontWeight: '400',
      lineHeight: '2.4rem',
    },
    table: {
      header: {
        background: '#FFF66D',
        fontFamily: font.secondary,
        fontWeight: '600',
        color: primaryPalette.grey.p800,
        fontSize: '1.4rem',
      },
      item: {
        color: '#666',
        background: '#F7F7F7',
        fontFamily: font.secondary,
        amountColor: '#666',
        fontSize: '1.6rem',
        lineHeight: '2.4rem',
      },
      error: {
        fontFamily: font.secondary,
        fontSize: '1.6rem',
        color: '#F43A38',
      },
      empty: {
        fontFamily: font.secondary,
        fontSize: '1.6rem',
        color: '#666',
      },
    },
    pagination: {
      activeColor: primaryPalette.grey.p800,
      disabledColor: primaryPalette.grey.p400,
    },
  },
  dropdown: {
    fontFamily: font.secondary,
    color: primaryPalette.grey.p500,
    borderColor: primaryPalette.grey.p900,
    labelColor: primaryPalette.grey.p800,
    hintColor: primaryPalette.grey.p400,
    hoverBackground: primaryPalette.grey.p100,
    titleFontWeight: '500',
    menuListHeight: '20rem',
    selected: {
      color: primaryPalette.grey.p800,
      iconColor: primaryPalette.grey.p800,
    },
  },
  dateSelector: {
    borderColor: primaryPalette.grey.p900,
    color: primaryPalette.grey.p900,
    fontFamily: font.secondary,
    label: {
      color: primaryPalette.grey.p500,
      fontFamily: font.secondary,
      fontWeight: '500',
    },
    calendar: {
      selectedColor: primaryPalette.yellow.p500,
      dayName: {
        color: primaryPalette.grey.p500,
      },
      today: {
        color: primaryPalette.grey.p100,
      },
    },
  },
  footer: {
    background: '#000',
    color: primaryPalette.grey.p0,
    fontFamily: font.secondary,
  },
  header: {
    background: primaryPalette.grey.p0,
    color: primaryPalette.grey.p0,
    fontFamily: font.primary,
    isCentered: true,
    borderBottom: 'none',
    boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.2)',
    userIcon: {
      stroke: primaryPalette.grey.p300,
      fill: primaryPalette.grey.p700,
    },
    userMenu: {
      title: {
        fontFamily: font.secondary,
        fontSize: '1.4rem',
        lineHeight: '2.1rem',
        color: '#666666',
        letterSpacing: '0',
      },
      text: {
        fontFamily: font.primary,
        fontSize: '2rem',
        fontWeight: '500',
        lineHeight: '3rem',
        color: primaryPalette.grey.p800,
      },
      link: {
        fontFamily: font.secondary,
        fontWeight: '600',
        color: primaryPalette.grey.p800,
        letterSpacing: '0.02rem',
      },
      icons: {
        userFill: primaryPalette.grey.p800,
        circleFill: '#fff',
        circleStroke: primaryPalette.grey.p800,
      },
      greeting: {
        color: primaryPalette.grey.p800,
        fontFamily: font.secondary,
        fontSize: '1.6rem',
        fontWeight: '600',
        lineHeight: '2.4rem',
      },
      balance: {
        color: primaryPalette.grey.p800,
        fontFamily: font.secondary,
        fontSize: '1.8rem',
        fontWeight: '600',
        lineHeight: '2.7rem',
      },
      avios: {
        color: primaryPalette.grey.p800,
        fontFamily: font.secondary,
        fontSize: '1.6rem',
        fontWeight: '400',
        lineHeight: '2.4rem',
      },
      aviosBalance: {
        fontFamily: font.primary,
        fontSize: '2.4rem',
        fontWeight: '500',
        lineHeight: '3.6rem',
        color: primaryPalette.grey.p800,
      },
      myAccount: {
        background: '#fff',
        hoverDecoration: 'underline',
      },
      logout: {
        background: '#fff',
        hoverDecoration: 'underline',
      },
      errorColor: '#F43A38',
      popupRightPosition: '0.8rem',
      expiryDateColor: primaryPalette.grey.p500,
    },
    rightBlock: {
      mobileDisplay: 'none',
    },
  },
  languageSelector: {
    container: {
      fontFamily: font.secondary,
      fontSize: '1.6rem',
      lineHeight: '2.4rem',
      fontWeight: '600',
      letterSpacing: '0',
      color: primaryPalette.grey.p800,
      padding: '0.5rem',
      tabletDisplay: 'inherit',
    },
    title: {
      fontFamily: font.primary,
      fontSize: '1.6rem',
      color: primaryPalette.grey.p900,
      lineHeight: '2.4rem',
    },
    button: {
      fontSize: '1.6rem',
      fontWeight: '500',
    },
    chevronStroke: primaryPalette.grey.p800,
  },
  help: {
    arrowColor: primaryPalette.grey.p800,
    background: primaryPalette.grey.p100,
    fontFamilyTitle: font.primary,
    fontFamilyText: font.primary,
    titleColor: primaryPalette.grey.p800,
    textColor: '#666',
    fontSizeText: '1.6rem',
    fontWeightText: '400',
    textTransformTitle: 'uppercase',
    fontSizeTitle: '2.8rem',
    fontWeightTitle: '500',
    lineHeightTitle: '4.2rem',
    description: {
      color: primaryPalette.grey.p800,
      fontFamily: font.primary,
      fontSize: '1.8rem',
      fontWeight: '500',
      textTransform: 'uppercase',
    },
    helpMenu: {
      border: `2px solid ${primaryPalette.grey.p800}`,
      hoverBorderColor: `2px solid ${primaryPalette.grey.p800}`,
    },
  },
  contactUs: {
    title: {
      color: primaryPalette.grey.p800,
      fontFamily: font.primary,
      fontSize: '3.2rem',
      fontWeight: '300',
      lineHeight: '4.8rem',
    },
    description: {
      color: primaryPalette.grey.p500,
      fontFamily: font.primary,
      fontSize: '1.4rem',
      lineHeight: '2.1rem',
    },
    formTitle: {
      color: primaryPalette.grey.p900,
      fontFamily: font.primary,
      fontSize: '2.2rem',
      fontWeight: '300',
      lineHeight: '3.3rem',
    },
    input: {
      borderColor: primaryPalette.grey.p500,
      textColor: primaryPalette.grey.p500,
      textFontFamily: font.primary,
    },
    textAreaDescription: {
      color: primaryPalette.grey.p600,
      fontFamily: font.primary,
      fontSize: '1.6rem',
    },
    textAreaCount: {
      color: primaryPalette.grey.p500,
      fontFamily: font.primary,
      fontSize: '1.4rem',
      fontWeight: '400',
    },
    submissionResult: {
      title: {
        color: primaryPalette.grey.p800,
        errorColor: '#D82100',
        fontFamily: font.primary,
        fontSize: '4rem',
        fontSizeTablet: '4rem',
        fontSizeMobile: '4rem',
        fontWeight: '500',
        lineHeight: '5rem',
        lineHeightTablet: '5rem',
        lineHeightMobile: '5rem',
        letterSpacing: '0',
        textTransform: 'uppercase',
      },
      description: {
        color: '#666',
        fontFamily: font.secondary,
        fontSize: '1.6rem',
        fontWeight: '400',
        lineHeight: '2.4rem',
      },
    },
  },
};

export default theme;
