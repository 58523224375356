import { useTranslation as nextTranslation } from 'next-i18next';
import { useGlobalContext } from '@/components/GlobalProvider';

type Options = {
  returnObjects?: boolean;
};

/**
 * useTranslation serves as an HOF for the useTranslation function
 * from next-i18next, functioning the same but adding the programme id
 * as a context for the translation files.
 * @param namespace Translation namespace
 * @returns HOF t function with the context being added automatically.
 */
export const useTranslation = (namespace: string) => {
  const { t: translate } = nextTranslation(namespace);
  const { programmeId } = useGlobalContext();

  return {
    t: <T = string>(key: string, config?: Omit<Options, 'context'>): T =>
      translate(key, { ...config, context: programmeId?.toLowerCase() }) as T,
  };
};
