import { css } from 'styled-components';

import FrutigerRegularWoff2 from '@/public/fonts/Frutiger/Frutiger-Regular.woff2';
import FrutigerRegularWoff from '@/public/fonts/Frutiger/Frutiger-Regular.woff';

import FrutigerMediumWoff2 from '@/public/fonts/Frutiger/Frutiger-Medium.woff2';
import FrutigerMediumWoff from '@/public/fonts/Frutiger/Frutiger-Medium.woff';

const fonts = css`
  @font-face {
    font-family: 'Frutiger Neue LT Pro';
    font-style: normal;
    font-weight: normal;
    src: url(${FrutigerRegularWoff2}) format('woff2'),
      url(${FrutigerRegularWoff}) format('woff');
  }

  @font-face {
    font-family: 'Frutiger Neue LT Pro';
    font-style: normal;
    font-weight: 500;
    src: url(${FrutigerMediumWoff2}) format('woff2'),
      url(${FrutigerMediumWoff}) format('woff');
  }
`;

export default fonts;
