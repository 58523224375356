import type { ProgrammeId } from '@/common/types';

const membershipDigitsToDisplay = {
  IBP: 9,
  ATRP: 9,
  BAEC: 8,
  AERC: 8,
};

export const convertMembershipNumberToDisplay = (
  programme: ProgrammeId,
  memberId?: string,
) => {
  if (!memberId) return null;

  return memberId.slice(membershipDigitsToDisplay[programme] * -1);
};
