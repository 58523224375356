import Icon from '@/components/Icon';
import styled from 'styled-components';

export const LogoutContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-left: 1rem;
`;

export const LogoutLink = styled.a`
  color: ${props => props.theme.header.userMenu.link.color};
  font-family: ${props => props.theme.logout.fontFamily};
  text-decoration: none;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: ${props => props.theme.header.userMenu.link.fontWeight};
  line-height: 2.4rem;
  text-transform: none;

  &:hover {
    color: ${props => props.theme.logout.color};
    text-decoration: none;
  }
`;

export const StyledLogoutIcon = styled(Icon).withConfig({
  shouldForwardProp: prop => prop === 'iconName',
})`
  cursor: pointer;
  fill: ${props => props.theme.header.userMenu.link.color};
  margin-right: 0.8rem;
  stroke-width: 20px;
`;
