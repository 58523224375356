import styled, { css } from 'styled-components';
import Icon from '../Icon';
import { media } from '@/utils/viewports';

export const LanguageSelectorContainer = styled.ul`
  position: relative;
  z-index: 1;
  background: none;
  outline: none;
  border: none;
  display: flex;
  height: 47px;
  align-items: center;
  color: ${props => props.theme.languageSelector.container.color};
  text-align: right;
  font-family: ${props => props.theme.languageSelector.container.fontFamily};
  font-size: ${props => props.theme.languageSelector.container.fontSize};
  font-style: normal;
  font-weight: ${props => props.theme.languageSelector.container.fontWeight};
  line-height: ${props => props.theme.languageSelector.container.lineHeight};
  letter-spacing: ${props =>
    props.theme.languageSelector.container.letterSpacing};

  ${media.l} {
    display: ${props => props.theme.languageSelector.container.tabletDisplay};
  }
`;

export const ChevronContainer = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-size: inherit;
  color: inherit;
`;

export const StyledIcon = styled(Icon)`
  stroke: ${props => props.theme.languageSelector.chevronStroke};
  cursor: pointer;
`;

export const StyledWorld = styled(Icon)`
  cursor: pointer;
  margin-right: 0.8rem;
`;

export const SelectedLanguageSmallScreens = styled.div`
  display: none;
  padding: ${props => props.theme.languageSelector.container.padding};
  border-radius: 5px;
  cursor: pointer;

  ${media.l} {
    display: flex;
    align-items: center;
  }
`;

export const SelectedLanguageLargeScreens = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => props.theme.languageSelector.container.padding};
  border-radius: 5px;
  cursor: pointer;

  ${media.l} {
    display: none;
  }
`;

export const StyledImage = styled.img`
  width: 2.4rem;
  height: 1.6rem;
  margin-right: 0.8rem;
`;

export const DropdownBox = styled.div`
  position: absolute;
  width: 26rem;
  list-style: none;
  padding: 2rem;
  margin: 0.8rem 0 0 0;
  right: -0.8rem;
  position: absolute;
  top: 100%;
  border-radius: 0.6rem;
  background: #fff;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
`;

export const DropdownContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const DropdownContainer = styled.div`
  width: 26rem;
  margin-bottom: 2rem;
`;

export const DropdownBoxTitle = styled.p`
  text-align: left;
  margin-bottom: 2.4rem;
  font-family: ${props => props.theme.languageSelector.title.fontFamily};
  font-size: ${props => props.theme.languageSelector.title.fontSize};
  color: ${props => props.theme.languageSelector.title.color};
  line-height: ${props => props.theme.languageSelector.title.lineHeight};
`;

export const ConfirmButton = styled.button`
  align-self: flex-start;
  height: 4.8rem;
  width: 26rem;
  box-shadow: ${props => props.theme.button.boxShadow};
  background-color: ${props => props.theme.button.background};
  border: none;
  color: ${props => props.theme.button.color};
  font-family: ${props => props.theme.button.fontFamily};
  font-size: ${props => props.theme.languageSelector.button.fontSize};
  font-style: normal;
  font-weight: ${props => props.theme.languageSelector.button.fontWeight};
  line-height: 2.6rem;
  letter-spacing: 0.02rem;
  border-radius: ${props => props.theme.button.borderRadius};
  cursor: pointer;
  text-transform: ${props => props.theme.button.textTransform};
`;

export const SelectedDescription = styled.span`
  ${({ theme }) =>
    theme.languageSelector.selectDescription &&
    css`
      color: ${theme.languageSelector.selectDescription.color};
      font-size: ${theme.languageSelector.selectDescription.fontSize};
      font-weight: ${theme.languageSelector.selectDescription.fontWeight};
      font-family: ${theme.languageSelector.selectDescription.fontFamily};
      line-height: ${theme.languageSelector.selectDescription.lineHeight};
    `}
`;
