import { css } from 'styled-components';

import SourceSansProRegularWoff2 from '@/public/fonts/SourceSansPro/SourceSansPro-Regular.woff2';
import SourceSansProRegularWoff from '@/public/fonts/SourceSansPro/SourceSansPro-Regular.woff';

import SourceSansProSemiBoldWoff2 from '@/public/fonts/SourceSansPro/SourceSansPro-Semibold.woff2';
import SourceSansProSemiBoldWoff from '@/public/fonts/SourceSansPro/SourceSansPro-Semibold.woff';

const fonts = css`
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: normal;
    src: url(${SourceSansProRegularWoff2}) format('woff2'),
      url(${SourceSansProRegularWoff}) format('woff');
  }

  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url(${SourceSansProSemiBoldWoff2}) format('woff2'),
      url(${SourceSansProSemiBoldWoff}) format('woff');
  }
`;

export default fonts;
