import { useTranslation } from '@/utils/useTranslation';
import {
  ChevronContainer,
  InfoAvios,
  InfoBalance,
  LoggedInView,
  StyledCircleIcon,
  StyledGreeting,
  StyledIcon,
  StyledUserIcon,
  UserIcon,
  UserImageContainer,
  UserInfo,
  UserInfoCompact,
  UserInfoContainer,
} from './styledComponents';
import { StyledIcon as StyledPersona } from '../LoginView/styledComponents';
import { useGlobalContext } from '@/components/GlobalProvider';

export type Props = {
  userData: {
    name?: string | null;
    balance?: string;
  };
  onClick: () => void;
};

const UserView = ({ userData, onClick }: Props) => {
  const { t } = useTranslation('header');
  const { programmeId } = useGlobalContext();

  return (
    <LoggedInView>
      <UserInfoContainer onClick={onClick} onKeyDown={onClick}>
        <UserInfoCompact>
          <UserImageContainer>
            {programmeId === 'IBP' ? (
              <StyledPersona iconName="persona" />
            ) : (
              <UserIcon>
                <StyledCircleIcon height={43} width={43} iconName="circle" />
                <StyledUserIcon height={19.5} width={17} iconName="user-icon" />
              </UserIcon>
            )}
          </UserImageContainer>
          <UserInfo>
            <StyledGreeting>
              {t('greeting')} {userData.name}!
            </StyledGreeting>
            <p>
              <InfoBalance>{userData.balance}</InfoBalance>{' '}
              <InfoAvios>Avios</InfoAvios>
            </p>
          </UserInfo>
          {programmeId !== 'ATRP' && (
            <ChevronContainer data-testid="header-user-chevron">
              <StyledIcon
                iconName={
                  programmeId === 'IBP' ? 'chevron-down-filled' : 'chevron'
                }
              />
            </ChevronContainer>
          )}
        </UserInfoCompact>
      </UserInfoContainer>
    </LoggedInView>
  );
};

export default UserView;
