import { css } from 'styled-components';

import VuelingPilcrowMediumWoff2 from '@/public/fonts/VuelingPilcrow/VuelingPilcrow-Medium.woff2';
import VuelingPilcrowMediumWoff from '@/public/fonts/VuelingPilcrow/VuelingPilcrow-Medium.woff';

const fonts = css`
  @font-face {
    font-family: 'Vueling Pilcrow';
    font-style: normal;
    font-weight: 500;
    src: url(${VuelingPilcrowMediumWoff2}) format('woff2'),
      url(${VuelingPilcrowMediumWoff}) format('woff');
  }
`;

export default fonts;
