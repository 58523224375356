import {
  ControlWrapper,
  ControlTitle,
  DropdownContainer,
  OptionTextWrapper,
  OptionSelectedTextWrapper,
  StyledIcon,
  StyledSelect,
} from './styledComponents';
import {
  components,
  type ControlProps,
  type OptionProps,
  type ActionMeta,
} from 'react-select';
import { useId, forwardRef } from 'react';

export type DropdownOption = {
  label: string;
  value: string | null;
};

export type Props = {
  title?: string;
  placeholder?: string;
  options: DropdownOption[];
  onSelect?: (newValue: unknown, actionMeta: ActionMeta<unknown>) => void;
  value?: DropdownOption;
};

const { Control, Option } = components;

export const ControlComponent = (props: ControlProps & { title?: string }) => (
  <ControlWrapper>
    {props.title && <ControlTitle>{props.title}</ControlTitle>}
    <Control {...props} />
  </ControlWrapper>
);

const OptionComponent = (props: OptionProps) => (
  <Option {...props}>
    {props.isSelected ? (
      <OptionSelectedTextWrapper>
        <span>{props.label}</span>
        <span>
          <StyledIcon iconName="check-mark" />
        </span>
      </OptionSelectedTextWrapper>
    ) : (
      <OptionTextWrapper>{props.label}</OptionTextWrapper>
    )}
  </Option>
);

type SelectProps = Props & {
  title?: string;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  innerRef: any;
};

const SelectComponent = ({
  title,
  options,
  onSelect,
  innerRef,
  placeholder,
  ...props
}: SelectProps) => {
  return (
    <StyledSelect
      ref={innerRef}
      instanceId={useId()}
      classNamePrefix="select"
      options={options}
      onChange={onSelect}
      placeholder={placeholder ?? 'All'}
      components={{
        Control: (controlProps: ControlProps) => (
          <ControlComponent {...controlProps} title={title} />
        ),
        Option: OptionComponent,
      }}
      {...props}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Dropdown = forwardRef<any, Props>((props, ref) => {
  return (
    <DropdownContainer>
      <SelectComponent innerRef={ref} {...props} />
    </DropdownContainer>
  );
});

export default Dropdown;
