import {
  LogoutContainer,
  StyledLogoutIcon,
  LogoutLink,
} from './styledComponents';
import { useUser } from '@auth0/nextjs-auth0/client';
import { useTranslation } from '@/utils/useTranslation';

const LogoutButton = () => {
  const { user } = useUser();
  const { t } = useTranslation('header');

  return (
    <>
      {user && (
        <LogoutContainer>
          <StyledLogoutIcon iconName="logout" />
          <LogoutLink href="/api/auth/logout">
            {t('subHeader.logout')}
          </LogoutLink>
        </LogoutContainer>
      )}
    </>
  );
};

export default LogoutButton;
