import { type ProgrammeId } from '@/common/types';
import { es, enGB } from 'date-fns/locale';

export const BAEC = 'BAEC';
export const AERC = 'AERC';
export const IBP = 'IBP';
export const ATRP = 'ATRP';

export const WALLET_REFRESH_TIMEOUT = parseInt(
  process.env.NEXT_PUBLIC_WALLET_REFRESH_TIMEOUT ?? '5000',
  10,
);
export const sharedTranslationNamespaces = [
  'common',
  'header',
  'sidebar',
  'footer',
  'filters',
  'toaster',
];

export const programmes: ProgrammeId[] = [BAEC, AERC, IBP, ATRP];
export const linkablePartners: string[] = [...programmes];

export const linkingServiceUrl = process.env.LINKING_SERVICE_BASE_URL!;

export const aviosApi = {
  apiIagloyaltyUrl: process.env.AVIOS_API_IAGLOYALTY_URL!,
  partnerIdentifierHeader: process.env.AVIOS_PARTNER_IDENTIFIER_HEADER!,
  timeout: parseInt(process.env.AVIOS_API_TIMEOUT!, 10),
  resetTimeout: parseInt(process.env.AVIOS_API_RESET_TIMEOUT!, 10),
};

// More info about this: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/X-Forwarded-Host
// This header property is used to get the host from the request when the request is proxied.
export const xForwardHeader = 'x-forwarded-host';

export const GTM_TRACKING_IDS: Record<ProgrammeId, string> = {
  BAEC: process.env.NEXT_PUBLIC_BAEC_GTM_TRACKING_ID!,
  IBP: process.env.NEXT_PUBLIC_IBP_GTM_TRACKING_ID!,
  ATRP: process.env.NEXT_PUBLIC_ATRP_GTM_TRACKING_ID!,
  AERC: process.env.NEXT_PUBLIC_AERC_GTM_TRACKING_ID!,
};

export const GOOGLE_SEARCH_CONSOLE_IDS: Record<string, string> = {
  BAEC: process.env.NEXT_PUBLIC_BAEC_SEARCH_CONSOLE_ID!,
  IBP: process.env.NEXT_PUBLIC_IBP_SEARCH_CONSOLE_ID!,
  ATRP: process.env.NEXT_PUBLIC_ATRP_SEARCH_CONSOLE_ID!,
};

export const HOTJAR_TRACKING_IDS: Record<ProgrammeId, number> = Object.freeze(
  programmes.reduce((hotjarTrackingIds, programme) => {
    hotjarTrackingIds[programme] = parseInt(
      process.env[`${programme}_HOTJAR_TRACKING_ID`]!,
      10,
    );
    return hotjarTrackingIds;
  }, {} as Record<ProgrammeId, number>),
);

// Check https://www.npmjs.com/package/@hotjar/browser for the latest version
export const HOTJAR_VERSION = 6;

type Auth0Configuration = {
  secret: string;
  baseUrl: string;
  issuerBaseUrl: string;
  clientId: string;
  clientSecret: string;
  audience: string;
};

export const auth0Mapping = (programmeId: ProgrammeId) => ({
  secret: process.env[`${programmeId}_B2C_AUTH0_SECRET`]!,
  baseUrl: process.env[`${programmeId}_B2C_AUTH0_BASE_URL`]!,
  issuerBaseUrl: process.env[`${programmeId}_B2C_AUTH0_ISSUER_BASE_URL`]!,
  clientId: process.env[`${programmeId}_B2C_AUTH0_CLIENT_ID`]!,
  clientSecret: process.env[`${programmeId}_B2C_AUTH0_CLIENT_SECRET`]!,
  audience: process.env[`${programmeId}_B2C_AUTH0_AUDIENCE`]!,
});

export const AUTH0_CONFIG = programmes.reduce((auth0Config, programmeId) => {
  auth0Config[programmeId] = auth0Mapping(programmeId);
  return auth0Config;
}, {} as Record<ProgrammeId, Auth0Configuration>);

export const b2bMapping = (programmeId: ProgrammeId) => ({
  clientId: process.env[`${programmeId}_M2M_AUTH0_CLIENT_ID`]!,
  baseUrl: process.env[`${programmeId}_M2M_AUTH0_URL`]!,
  audience: process.env[`${programmeId}_M2M_AUTH0_AUDIENCE`]!,
});

// We keep a record matching all the hosts with
// their respective programme ids.
// Example for dev environment:
// ba.apigw.programme.dev.iagl.digital : BAEC
export const programmeByHost: Record<string, ProgrammeId> = {
  [process.env.BAEC_HOST as string]: BAEC,
  [process.env.AERC_HOST as string]: AERC,
  [process.env.ATRP_HOST as string]: ATRP,
  [process.env.IBP_HOST as string]: IBP,
};

export const EMAIL_QUEUE_URL = process.env.EMAIL_QUEUE_URL;
export const LINKING_EMAIL_ADDRESS = process.env.LINKING_EMAIL_ADDRESS;
export const CONTACT_US_PAGE_ATRP_EMAIL =
  process.env.CONTACT_US_PAGE_ATRP_EMAIL;

export const languagesMapping: Record<string, Locale> = {
  en: enGB,
  es,
};
