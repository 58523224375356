import { css } from 'styled-components';

import MyliusModernExtraLightWoff2 from '@/public/fonts/MyliusModern/MyliusModern-ExtraLight.woff2';
import MyliusModernExtraLightWoff from '@/public/fonts/MyliusModern/MyliusModern-ExtraLight.woff';

import MyliusModernLightWoff2 from '@/public/fonts/MyliusModern/MyliusModern-Light.woff2';
import MyliusModernLightWoff from '@/public/fonts/MyliusModern/MyliusModern-Light.woff';

import MyliusModernRegularWoff2 from '@/public/fonts/MyliusModern/MyliusModern-Regular.woff2';
import MyliusModernRegularWoff from '@/public/fonts/MyliusModern/MyliusModern-Regular.woff';

import MyliusModernBoldWoff2 from '@/public/fonts/MyliusModern/MyliusModern-Bold.woff2';
import MyliusModernBoldWoff from '@/public/fonts/MyliusModern/MyliusModern-Bold.woff';

const fonts = css`
  @font-face {
    font-family: 'Mylius Modern';
    font-style: normal;
    font-weight: 200;
    src: url(${MyliusModernExtraLightWoff2}) format('woff2'),
      url(${MyliusModernExtraLightWoff}) format('woff');
  }

  @font-face {
    font-family: 'Mylius Modern';
    font-style: normal;
    font-weight: 300;
    src: url(${MyliusModernLightWoff2}) format('woff2'),
      url(${MyliusModernLightWoff}) format('woff');
  }

  @font-face {
    font-family: 'Mylius Modern';
    font-style: normal;
    font-weight: normal;
    src: url(${MyliusModernRegularWoff2}) format('woff2'),
      url(${MyliusModernRegularWoff}) format('woff');
  }

  @font-face {
    font-family: 'Mylius Modern';
    font-style: normal;
    font-weight: bold;
    src: url(${MyliusModernBoldWoff2}) format('woff2'),
      url(${MyliusModernBoldWoff}) format('woff');
  }
`;

export default fonts;
