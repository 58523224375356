import { media } from '@/utils/viewports';
import styled from 'styled-components';

export const SubHeaderContainer = styled.div`
  grid-area: SubHeader;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.subHeader.borderColor};
  margin-left: 18.4rem;
  margin-right: 18.4rem;
  margin: ${props => props.theme.subHeader.margin};
  justify-self: center;
  max-width: 123.2rem;
  width: 100%;

  ${media.xxl} {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }

  ${media.xl} {
    justify-self: unset;
    max-width: unset;
    width: unset;
  }

  ${media.m} {
    margin: 1.5rem 0 1rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
`;

export const SubHeaderTitle = styled.h1`
  font-size: ${props => props.theme.subHeader.fontSize};
  line-height: ${props => props.theme.subHeader.lineHeight}; //3.8rem;
  color: ${props => props.theme.subHeader.titleColor};
  font-family: ${props => props.theme.subHeader.fontFamily};
  font-weight: ${props => props.theme.subHeader.fontWeight};
  padding: ${props => props.theme.subHeader.titlePadding};
  text-transform: ${props => props.theme.subHeader.textTransform};

  ${media.m} {
    font-size: ${props => props.theme.subHeader.mobile.fontSize}; //2rem;
    line-height: ${props => props.theme.subHeader.mobile.lineHeight}; //2.8rem;
  }
`;

export const HeaderSeparator = styled.div`
  width: 100%;
  height: 2rem;
`;
