import { forwardRef, useMemo } from 'react';
import { useTranslation } from '@/utils/useTranslation';
import {
  StyledAviosIcon,
  UserInfoPopup,
  BalanceContainer,
  LogoutContainer,
  MembershipNumberContainer,
  MyAccountContainer,
  UserPopupInfoTitle,
  MembershipNumber,
  Balance,
  UserInfoPopupContainer,
  MyAccountIcon,
  MyAccountText,
  ExpiryDate,
  MyAccountLink,
  BalanceRow,
} from './styledComponents';
import LogoutButton from '@/components/LogoutButton';
import { useGlobalContext } from '@/components/GlobalProvider';

export type Props = {
  userData: {
    name?: string | null;
    balance?: string;
    membershipNumber?: string | null;
    balanceExpiryDate?: string | null;
  };
};

const LINK_PREFIX = 'https://';

const UserInfo = forwardRef(
  ({ userData }: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { programmeId } = useGlobalContext();
    const { t } = useTranslation('header');

    const myAccountLink = useMemo(() => {
      return t(`myAccount.links.${programmeId}`);
    }, [t, programmeId]);

    return (
      <UserInfoPopup data-testid="user-info-popup" ref={ref}>
        <UserInfoPopupContainer>
          <MembershipNumberContainer>
            <UserPopupInfoTitle>{t('membershipNumber')}</UserPopupInfoTitle>
            <MembershipNumber data-testid="user-popup-membership-number">
              {userData.membershipNumber}
            </MembershipNumber>
          </MembershipNumberContainer>
          <BalanceContainer>
            <UserPopupInfoTitle>{t('aviosBalance')}</UserPopupInfoTitle>
            <BalanceRow>
              <StyledAviosIcon
                iconName={programmeId === 'BAEC' ? 'simple-avios' : 'new-avios'}
                $hasStroke={programmeId === 'BAEC'}
              />
              {userData.balance ? (
                <Balance $isError={false} data-testid="user-popup-balance">
                  {userData.balance}
                </Balance>
              ) : (
                <Balance $isError={true} data-testid="user-popup-error-balance">
                  -
                </Balance>
              )}
            </BalanceRow>
            {userData.balanceExpiryDate && (
              <ExpiryDate $isError={false} data-testid="user-popup-expiry-date">
                {t('expDate')}: {userData.balanceExpiryDate}
              </ExpiryDate>
            )}
          </BalanceContainer>
        </UserInfoPopupContainer>
        <MyAccountContainer>
          <MyAccountIcon height={14} width={16} iconName="user-icon" />
          {myAccountLink.includes(LINK_PREFIX) ? (
            <MyAccountLink href={myAccountLink} target="_blank">
              {t('myAccount.text')}
            </MyAccountLink>
          ) : (
            <MyAccountText>{t('myAccount.text')}</MyAccountText>
          )}
        </MyAccountContainer>
        <LogoutContainer>
          <LogoutButton />
        </LogoutContainer>
      </UserInfoPopup>
    );
  },
);

export default UserInfo;
