import { useTranslation } from '@/utils/useTranslation';
import {
  SidebarContainer,
  SidebarMenu,
  SidebarListContainer,
  SidebarListItem,
  SidebarLink,
} from './styledComponents';

type SidebarLink = {
  label: string;
  link: string;
};

const Sidebar = () => {
  const { t } = useTranslation('sidebar');

  const sidebarlinks = t<Array<SidebarLink>>('links', {
    returnObjects: true,
  });

  if (!(sidebarlinks instanceof Array) || sidebarlinks.length === 0)
    return null;

  return (
    <SidebarContainer>
      <SidebarMenu>
        <SidebarListContainer>
          {sidebarlinks.map(item => {
            const isLocalLink = item.link.startsWith('/');
            return (
              <SidebarListItem key={item.label}>
                <SidebarLink
                  target={isLocalLink ? '_self' : '_blank'}
                  href={item.link}
                  rel="noreferrer"
                >
                  {item.label}
                </SidebarLink>
              </SidebarListItem>
            );
          })}
        </SidebarListContainer>
      </SidebarMenu>
    </SidebarContainer>
  );
};

export default Sidebar;
