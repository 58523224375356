import { useCallback, useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';

const DatadogRum = () => {
  const checkUserTrackingPermission = useCallback(() => {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT !== 'prd') {
      // On non prod environments, having RUM always enabled
      // helps us with the troubleshoot of issues.
      return true;
    }

    // @ts-expect-error: Window does not have OneTrust natively.
    return window?.OnetrustActiveGroups?.includes('C0002') ?? false;
  }, []);

  useEffect(() => {
    if (
      process.env.NODE_ENV === 'production' &&
      process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID &&
      process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN &&
      process.env.NEXT_PUBLIC_SERVICE_NAME &&
      process.env.NEXT_PUBLIC_ENVIRONMENT
    ) {
      const permissionGranted = checkUserTrackingPermission()
        ? 'granted'
        : 'not-granted';
      datadogRum.init({
        applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
        clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
        site: process.env.NEXT_PUBLIC_DATADOG_SITE,
        service: process.env.NEXT_PUBLIC_SERVICE_NAME,
        env: process.env.NEXT_PUBLIC_ENVIRONMENT,
        trackResources: true,
        trackLongTasks: true,
        version: process.env.NEXT_PUBLIC_APP_VERSION,
        trackUserInteractions: true,
        useSecureSessionCookie: true,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 10,
        trackingConsent: permissionGranted,
      });
      datadogRum.startSessionReplayRecording();
    }
  }, []);

  return <></>;
};
export default DatadogRum;
