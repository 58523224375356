import Link from 'next/link';
import styled, { css } from 'styled-components';
import { media } from '@/utils/viewports';

export const FooterContainer = styled.div`
  grid-area: Footer;
  background: ${props => props.theme.footer.background};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
  padding: 0 10rem;

  ${media.m} {
    height: 8rem;
    padding: 0 2.4rem;
  }

  // TODO: Change this to an appropriate viewport.
  @media (max-width: 767px) {
    height: unset;
    padding: 1.6rem 1.6rem 3.6rem;
  }
`;

export const LinkContainerAtrp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  // TODO: Change this to an appropriate viewport.
  @media (max-width: 1023px) {
    flex: 1;
    justify-content: space-between;
  }

  // TODO: Change this to an appropriate viewport.
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.m} {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`;

const linkSharedStyles = css`
  cursor: pointer;
  color: ${props => props.theme.footer.color};
  font-family: ${props => props.theme.footer.fontFamily};
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  text-decoration: none;
  margin-right: 6rem;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }

  // TODO: Change this to an appropriate viewport.
  @media (max-width: 1023px) {
    margin-right: unset;
  }

  // TODO: Change this to an appropriate viewport.
  @media (max-width: 767px) {
    margin-top: 2.8rem;
  }
`;

export const ExternalLink = styled.a`
  ${linkSharedStyles};
`;

export const LinkTextAtrp = styled(Link).withConfig({
  shouldForwardProp: prop =>
    prop !== 'bigScreenLabel' && prop !== 'smallScreenLabel',
})<{
  bigScreenLabel?: string;
  smallScreenLabel?: string;
}>`
  ${linkSharedStyles};

  &:last-of-type {
    margin-right: 0;
  }

  ${props =>
    props.bigScreenLabel &&
    props.smallScreenLabel &&
    css`
      &::before {
        content: '${props.bigScreenLabel}';
        ${media.m} {
          content: '${props.smallScreenLabel}';
        }
      }
    `}
`;

export const LinkText = styled(Link).withConfig({
  shouldForwardProp: prop =>
    prop !== 'bigScreenLabel' && prop !== 'smallScreenLabel',
})<{
  bigScreenLabel?: string;
  smallScreenLabel?: string;
}>`
  cursor: pointer;
  color: ${props => props.theme.footer.color};
  font-family: ${props => props.theme.footer.fontFamily};
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.2rem;
  text-decoration: none;
  margin-right: 4rem;
  white-space: nowrap;

  ${media.m} {
    margin-right: 5rem;
    font-size: 1.2rem;
    line-height: 1.8rem;
  }

  ${media.s} {
    margin-right: 3.5rem;
  }

  ${props =>
    props.theme.footer.colorHover &&
    css`
      &:hover {
        color: ${props.theme.footer.colorHover};
      }
    `}

  ${props =>
    props.bigScreenLabel &&
    props.smallScreenLabel &&
    css`
      &::before {
        content: '${props.bigScreenLabel}';
        ${media.m} {
          content: '${props.smallScreenLabel}';
        }
      }
    `}
`;

export const PartnerText = styled.p`
  cursor: pointer;
  color: ${props => props.theme.footer.color};
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.2rem;
  white-space: nowrap;
  margin-left: 1rem;

  ${media.s} {
    display: none;
  }

  ${props =>
    props.theme.footer.colorHover &&
    css`
      &:hover {
        color: ${props.theme.footer.colorHover};
      }
    `}
`;

export const ImageAtrp = styled.img`
  height: 3.6rem;
  width: 3.6rem;
  margin-right: 6rem;

  // TODO: Change this to an appropriate viewport.
  @media (max-width: 1023px) {
    margin-right: unset;
  }

  // TODO: Change this to an appropriate viewport.
  @media (max-width: 767px) {
    padding: 1.467rem;
  }
`;

export const Image = styled.img`
  height: 3.6rem;
  width: 3.6rem;
`;

export const PartnerLinkContainer = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8rem;
  text-decoration: none;

  ${media.m} {
    margin-right: 6rem;
  }

  ${media.s} {
    margin-right: 2.28rem;
  }
`;
