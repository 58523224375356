export const viewports = {
  xs: 319,
  s: 419,
  m: 599,
  l: 979,
  xl: 1279,
  xxl: 1599,
};

export const media = {
  xs: `@media (max-width: ${viewports.xs}px)`,
  s: `@media (max-width: ${viewports.s}px)`,
  m: `@media (max-width: ${viewports.m}px)`,
  l: `@media (max-width: ${viewports.l}px)`,
  xl: `@media (max-width: ${viewports.xl}px)`,
  xxl: `@media (max-width: ${viewports.xxl}px)`,
};
