import styled from 'styled-components';
import { media } from '@/utils/viewports';
import Icon from '@/components/Icon';
import Select from 'react-select';

export const DropdownContainer = styled.div`
  text-align: left;

  & .select__single-value {
    color: ${props => props.theme.dropdown.selected.color};
  }

  & .select__placeholder {
    color: ${props => props.theme.dropdown.hintColor};
    font-size: 1.6rem;
  }

  & .select__menu {
    margin-top: 0;
  }

  & .select__menu-list {
    border-radius: 4px;
  }

  & .select__control {
    border: 0;
    box-shadow: none;
    min-height: 3.2rem;
    width: 100%;

    ${media.m} {
      min-height: 2.8rem;
    }
    &:hover {
      border: 0;
    }
  }

  & .select__option {
    background-color: #ffffff;
    &:hover {
      background-color: ${props => props.theme.dropdown.hoverBackground};
      cursor: pointer;
    }
  }

  & .select__indicator-separator {
    display: none;
  }

  & .select__input-container {
    padding: 0px;
    margin: 0;
  }

  & .select__value-container {
    padding: 0px;
  }

  & .select__single-value {
    margin: 0;
  }

  & .select__dropdown-indicator {
    color: ${props => props.theme.dropdown.labelColor};
    &:hover {
      color: ${props => props.theme.dropdown.labelColor};
    }
    padding: 0.5rem 0 0 0.5rem;
  }
`;

export const ControlWrapper = styled.div`
  padding: 0.5rem 1rem 0 0.5rem;
  border: 1px solid ${props => props.theme.dropdown.borderColor};
  background-color: #ffffff;
  border-radius: 4px;
`;

export const ControlTitle = styled.p`
  color: ${props => props.theme.dropdown.labelColor};
  font-size: 1.2rem;
  font-family: ${props => props.theme.dropdown.fontFamily};
  font-weight: ${props => props.theme.dropdown.titleFontWeight};

  ${media.m} {
    font-size: 1rem;
  }
`;

export const OptionTextWrapper = styled.div`
  color: ${props => props.theme.dropdown.color};
  font-size: 1.6rem;
  font-family: ${props => props.theme.dropdown.fontFamily};

  ${media.m} {
    font-size: 1.4rem;
  }
`;

export const OptionSelectedTextWrapper = styled.div`
  color: ${props => props.theme.dropdown.selected.color};
  font-size: 1.6rem;
  display: flex;
  justify-content: space-between;

  ${media.m} {
    font-size: 1.4rem;
  }
`;

export const StyledIcon = styled(Icon)`
  stroke: ${props => props.theme.dropdown.selected.iconColor};
`;

export const StyledSelect = styled(Select)`
  & .select__menu-list {
    max-height: ${props => props.theme.dropdown.menuListHeight};
  }
`;
