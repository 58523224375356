import React, { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from '@/utils/useTranslation';
import {
  LanguageSelectorContainer,
  StyledIcon,
  StyledImage,
  DropdownBox,
  DropdownContent,
  DropdownContainer,
  DropdownBoxTitle,
  ConfirmButton,
  SelectedLanguageSmallScreens,
  SelectedLanguageLargeScreens,
  ChevronContainer,
  StyledWorld,
  SelectedDescription,
} from './styledComponents';
import Dropdown, { type DropdownOption } from '../Dropdown';
import Cookies from 'js-cookie';
import { useGlobalContext } from '../GlobalProvider';

export type LanguageSelectorProps = {
  id: string;
  value: string;
};

const languageObjects: LanguageSelectorProps[] = [
  { value: 'English', id: 'en' },
  { value: 'Español', id: 'es' },
];

const languages: DropdownOption[] = languageObjects.map(language => ({
  value: language.id,
  label: language.value,
}));

const LanguageSelector = () => {
  const { t } = useTranslation('header');
  const router = useRouter();
  let { locale } = router;

  const { programmeId } = useGlobalContext();

  const [selectedLanguage, setSelectedLanguage] = useState<
    LanguageSelectorProps | undefined
  >({
    id: locale ?? 'es',
    value: locale === 'es' ? 'Spanish' : 'English',
  });

  const [language, setLanguage] = useState<DropdownOption>({
    value: selectedLanguage?.id as string,
    label: selectedLanguage?.value as string,
  });

  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const localeFromCookie: string | undefined = Cookies.get('NEXT_LOCALE');
    if (localeFromCookie) {
      const localeSet = languageObjects.find(
        language => language.id === localeFromCookie,
      );
      if (localeSet) {
        handleSelectedLanguage(localeSet?.value);
        setLanguage({ value: localeSet?.id, label: localeSet.value });
        locale = localeSet?.value;
      }
    }
  }, []);

  //handle closing of popup when click outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        // Clicked outside the language selector -> close it
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLanguage = (language: unknown) => {
    setLanguage(language as DropdownOption);
  };

  const handleSelectedLanguage = (value: string) => {
    const languageSelection = languageObjects.find(
      language => language.value === value,
    );
    if (languageSelection) {
      const { id } = languageSelection;
      setSelectedLanguage({ id, value });
      router.push(router.route, router.asPath, {
        locale: id,
      });
      Cookies.set('NEXT_LOCALE', id);
    }

    setIsOpen(false);
  };

  return (
    <LanguageSelectorContainer id="languageSelector">
      <SelectedLanguage
        handleToggleDropdown={handleToggleDropdown}
        selectedLanguage={selectedLanguage}
      />
      <ChevronContainer
        data-testid="language-selector-chevron"
        onClick={handleToggleDropdown}
        onKeyDown={handleToggleDropdown}
      >
        <StyledIcon
          iconName={programmeId === 'IBP' ? 'chevron-down-filled' : 'chevron'}
          aria-label="Language selector dropdown"
        />
      </ChevronContainer>
      {isOpen && (
        <DropdownBox data-testid="language-selector-popup" ref={popupRef}>
          <DropdownContent>
            <DropdownBoxTitle>{t('languageSelector.title')}</DropdownBoxTitle>
            <DropdownContainer>
              <Dropdown
                title={t('languageSelector.languageTitle')}
                options={languages}
                onSelect={handleLanguage}
                value={language}
              />
            </DropdownContainer>
            <ConfirmButton
              onClick={() => handleSelectedLanguage(language.label)}
            >
              {t('languageSelector.confirm')}
            </ConfirmButton>
          </DropdownContent>
        </DropdownBox>
      )}
    </LanguageSelectorContainer>
  );
};

type SelectedLanguageProps = {
  handleToggleDropdown: () => void;
  selectedLanguage: LanguageSelectorProps | undefined;
};

const SelectedLanguage = ({
  handleToggleDropdown,
  selectedLanguage,
}: SelectedLanguageProps) => {
  const { programmeId } = useGlobalContext();

  return (
    <>
      <SelectedLanguageSmallScreens onClick={handleToggleDropdown}>
        {programmeId === 'IBP' ? (
          <StyledWorld iconName="world" />
        ) : (
          <StyledImage
            src={`/assets/flags/${selectedLanguage?.id}.png`}
            alt=""
            key={`flag-${selectedLanguage?.id}`}
          />
        )}
        <SelectedDescription>
          {selectedLanguage?.id.toUpperCase()}
        </SelectedDescription>
      </SelectedLanguageSmallScreens>
      <SelectedLanguageLargeScreens onClick={handleToggleDropdown}>
        {programmeId === 'IBP' && <StyledWorld iconName="world" />}
        {programmeId !== 'ATRP' && programmeId !== 'IBP' && (
          <StyledImage
            src={`/assets/flags/${selectedLanguage?.id}.png`}
            alt=""
            width={24}
            height={16}
            key={`flag-${selectedLanguage?.id}`}
          />
        )}
        <SelectedDescription>
          {selectedLanguage?.value} - {selectedLanguage?.id.toUpperCase()}
        </SelectedDescription>
      </SelectedLanguageLargeScreens>
    </>
  );
};

export default LanguageSelector;
