import styled from 'styled-components';
import { media } from '@/utils/viewports';

export const MainLayoutContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto 1fr auto;
  grid-template-areas:
    'Header'
    'SubHeader'
    'ContentContainer'
    'Footer';
  height: 100vh;
  overflow-x: hidden;

  ${media.l} {
    grid-template-columns: 100%;
    grid-template-rows: 1fr 0fr 10fr 1fr;
    grid-template-areas:
      'Header'
      'SubHeader'
      'ContentContainer'
      'Footer';
  }
`;

export const PageLayoutContainer = styled.div`
  grid-area: PageLayoutContainer;
  overflow: hidden;
`;

export const ContentContainer = styled.div`
  grid-area: ContentContainer;
  display: grid;
  grid-template-columns: minmax(18.4rem, auto) 29rem 94.2rem minmax(
      18.4rem,
      auto
    );
  grid-template-rows: 1fr;
  grid-template-areas: 'LeftMargin SidebarContainer PageLayoutContainer RightMargin';

  ${media.xxl} {
    grid-template-columns: minmax(2.4rem, auto) 29rem 94.2rem minmax(
        2.4rem,
        auto
      );
  }

  ${media.xl} {
    grid-template-columns: 2.4rem 1fr 3fr 2.4rem;
  }

  ${media.l} {
    grid-template-columns: 2.4rem 1fr 2.4rem;
    grid-template-rows: 10.8rem 1fr;
    grid-template-areas:
      'LeftMargin SidebarContainer RightMargin'
      'LeftMargin PageLayoutContainer RightMargin';
  }

  ${media.m} {
    margin-right: 0rem;
    grid-template-rows: 10.8rem 1fr;
    grid-template-columns: 100%;
    grid-template-areas:
      'SidebarContainer'
      'PageLayoutContainer';
  }
`;
