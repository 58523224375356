import { useIdleTimer } from 'react-idle-timer';
import { useState } from 'react';

type Props = {
  timeout?: number;
  throttle?: number;
};

// The timeout is 20 minutes which
// corresponds to 1200000 milliseconds.
const defaultTimeout = process.env.SESSION_TIMEOUT
  ? parseInt(process.env.SESSION_TIMEOUT)
  : 1200000;
const defaultThrottle = 1000;

/**
 * Hook to detect if the user has been inactive for given period of time.
 * Upon being considered idle, a callback is invoked.
 */
export const useIdleDetection = ({
  timeout = defaultTimeout,
  throttle = defaultThrottle,
}: Props = {}) => {
  const [isIdle, setIsIdle] = useState(false);

  // Function to be called when the user is
  // considered to be idle.
  const onIdle = () => {
    setIsIdle(true);
  };

  useIdleTimer({
    onIdle,
    timeout,
    throttle,
  });

  return isIdle;
};
