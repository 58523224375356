import { useEffect, useMemo, useRef, useState } from 'react';
import {
  HeaderContainer,
  RightBlock,
  MainLogoImage,
  BAECMobileOnlyLogoImage,
  MenuIcon,
  ContentContainer,
  StyledAnchor,
  StyledAviosImage,
  StyledRow,
  StyledOneWorld,
  StyledOneWorldAnchor,
  IberiaLogoImage,
  VuelingLogoImage,
} from './styledComponents';
import LanguageSelector from '../LanguageSelector';
import { programmePublicInfo } from '@/utils/names';
import { useUser } from '@auth0/nextjs-auth0/client';
import type { ProgrammeId } from '@/common/types';
import { useGlobalContext } from '@/components/GlobalProvider';
import UserInfo from './components/UserInfo';
import UserView from './components/UserView';
import { convertMembershipNumberToDisplay } from '@/utils/membershipNumber';
import LoginView from './components/LoginView';
import format from 'date-fns/format';
import { useRouter } from 'next/router';
import { languagesMapping } from '@/utils/constants';

export type HeaderProps = {
  singleAviosBalance?: number | null;
  singleAviosBalanceExpiryDate?: string;
};

const Header = ({
  singleAviosBalance,
  singleAviosBalanceExpiryDate,
}: HeaderProps) => {
  const { programmeId } = useGlobalContext();
  const popupRef = useRef<HTMLDivElement>(null);
  const [displayUserInfo, setDisplayUserInfo] = useState<boolean>(false);
  const router = useRouter();

  useEffect(() => {
    // Handles the logic of closing the user info popup
    // if the ser clicks outside the popup.

    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        // Clicked outside the userInfoPopup -> close it
        setDisplayUserInfo(false);
      }
    };

    if (displayUserInfo) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [displayUserInfo]);

  const { user } = useUser();

  const userData = useMemo(() => {
    return {
      name: user?.name,
      balance: singleAviosBalance?.toLocaleString('en-US'),
      membershipNumber:
        user &&
        convertMembershipNumberToDisplay(
          programmeId!,
          user.sub?.split('|')[1] as string,
        ),
      balanceExpiryDate: singleAviosBalanceExpiryDate
        ? format(new Date(singleAviosBalanceExpiryDate), 'dd MMMM yyy', {
            locale: languagesMapping[router.locale ?? 'en'],
          })
        : null,
    };
  }, [user, singleAviosBalance]);

  return (
    <HeaderContainer data-testid="header">
      <ContentContainer>
        <StyledRow>
          <StyledAnchor
            href={`https://www.${programmePublicInfo[programmeId!].domainName}`}
          >
            <LogoImage programmeId={programmeId!} />
          </StyledAnchor>
          {programmeId === 'IBP' && (
            <StyledAviosImage src={'/assets/logo_avios.png'} alt="" />
          )}
        </StyledRow>
        {user && programmeId !== 'IBP' && (
          // For the time being, while the mobile details are not
          // finalized, we hide the menu for logged out users.
          <MenuIcon
            height={24}
            width={24}
            iconName="menu"
            onClick={() => setDisplayUserInfo(!displayUserInfo)}
          />
        )}
        <RightBlock>
          <LanguageSelector />
          {user && (
            <UserView
              userData={userData}
              onClick={() => setDisplayUserInfo(!displayUserInfo)}
            />
          )}
          {!user && programmeId === 'IBP' && <LoginView />}
          {programmeId === 'IBP' && (
            <StyledOneWorldAnchor href="https://www.iberia.com/es/nuestras-alianzas/oneworld/">
              <StyledOneWorld
                src="/assets/logos/oneworld.svg"
                alt="OneWorld logo"
              />
            </StyledOneWorldAnchor>
          )}
        </RightBlock>
        {displayUserInfo && <UserInfo ref={popupRef} userData={userData} />}
      </ContentContainer>
    </HeaderContainer>
  );
};

type LogoImageProps = { programmeId: ProgrammeId };

const LogoImage = ({ programmeId }: LogoImageProps) => {
  if (programmeId === 'IBP')
    return (
      <IberiaLogoImage
        src={'/assets/logos/withName/logo_IBP.svg'}
        alt=""
        $priority={true}
      />
    );

  if (programmeId === 'ATRP') {
    return (
      <VuelingLogoImage
        src={'/assets/logos/withName/logo_ATRP.jpg'}
        alt="Vueling Club logo"
        $priority={true}
      />
    );
  }

  return (
    <>
      <BAECMobileOnlyLogoImage
        src={`/assets/logos/transparent/logo_${programmeId?.toUpperCase()}.svg`}
        alt=""
        $isBAEC={programmeId === 'BAEC'}
      />
      <MainLogoImage
        src={`/assets/logos/withName/logo_${programmeId?.toUpperCase()}.svg`}
        alt=""
        $isBAEC={programmeId === 'BAEC'}
        $priority={true}
      />
    </>
  );
};

export default Header;
