import {
  ThemeProvider,
  type DefaultTheme,
  StyleSheetManager,
} from 'styled-components';
import type { ProgrammeId } from '@/common/types';
import { ATRP, BAEC, IBP, AERC } from '@/utils/constants';
import FontStyles from './styles';

import AtrpTheme from './themes/atrp';
import BaecTheme from './themes/baec';
import IbpTheme from './themes/ibp';
import AercTheme from './themes/aerc';

interface Props {
  programmeId: ProgrammeId;
  children: React.ReactNode;
}

export const themes: Record<ProgrammeId, DefaultTheme> = {
  [ATRP]: AtrpTheme,
  [BAEC]: BaecTheme,
  [IBP]: IbpTheme,
  [AERC]: AercTheme,
};

const Theme = ({ programmeId, children }: Props) => (
  <StyleSheetManager enableVendorPrefixes>
    <ThemeProvider theme={themes[programmeId]}>
      <FontStyles programmeId={programmeId} />
      {children}
    </ThemeProvider>
  </StyleSheetManager>
);

export default Theme;
