import Head from 'next/head';
import { useTranslation } from '@/utils/useTranslation';

const PageTitle = () => {
  const { t } = useTranslation('common');
  const title = t('pageTitle');

  return (
    <Head>
      <title>{title}</title>
      <meta property="og:title" content={title} key="title" />
    </Head>
  );
};

export default PageTitle;
