import { useTranslation } from '@/utils/useTranslation';
import {
  Wrapper,
  InnerWrapper,
  StyledSpan,
  StyledIcon,
} from './styledComponents';

const LoginView = () => {
  const { t } = useTranslation('header');

  return (
    <Wrapper>
      <InnerWrapper href="/wallet">
        <StyledIcon iconName="persona" />
        <StyledSpan>{t('login')}</StyledSpan>
      </InnerWrapper>
    </Wrapper>
  );
};

export default LoginView;
