import styled from 'styled-components';
import Link from 'next/link';
import { media } from '@/utils/viewports';

export const SidebarContainer = styled.div`
  background-color: #fff;
  display: flex;
  grid-area: SidebarContainer;
  height: fit-content;
  margin-top: 4rem;
  justify-content: end;
  width: 100%;

  ${media.m} {
    margin-top: 3rem;
  }
`;

export const SidebarMenu = styled.div`
  background: ${props => props.theme.sidebar.menuBackground};
  border-radius: 8px;
  max-width: 29rem;
  min-width: 21.5rem;
  padding: 1rem 1.6rem;
  width: 100%;
  box-sizing: border-box;

  ${media.l} {
    max-width: unset;
    min-width: unset;
    overflow-x: scroll;
    padding: 0.8rem 1rem;
    white-space: nowrap;
    // At this threshold, the element will have to fill in all the width and have
    // a scroll for the overflow.
    // In order to achieve the goal, we calculate the viewport width minus
    // 4.8rem that represent the value margins.
    width: calc(100vw - 4.8rem);

    // Hiding the scrollbar
    scrollbar-width: none; // Firefox
    -ms-overflow-style: none; // IE & Edge

    //Webkit based browsers
    &::-webkit-scrollbar {
      display: none;
    }
  }

  ${media.m} {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
`;

export const SidebarListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding: 0;

  ${media.l} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
  }
`;

export const SidebarListItem = styled.li`
  color: ${props => props.theme.sidebar.listItem.color};
  font-family: ${props => props.theme.sidebar.listItem.fontFamily};
  font-size: ${props => props.theme.sidebar.listItem.fontSize};
  line-height: ${props => props.theme.sidebar.listItem.lineHeight};
  font-weight: ${props => props.theme.sidebar.listItem.fontWeight};
  list-style: none;
  padding: 1.2rem 0.8rem;
  width: fit-content;
  position: relative;
  text-transform: ${props => props.theme.sidebar.listItem.textTransform};

  &:not(:first-child) {
    margin-top: 0.4rem;

    ${media.l} {
      margin-top: unset;
    }
  }
`;

export const SidebarLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:hover {
    color: ${props => props.theme.sidebar.link.colorHover};
  }
`;

export const ActiveHighlight = styled.div`
  height: 0.2rem;
  width: 100%;
  background-color: ${props => props.theme.sidebar.listItem.colorHighlight};
  position: absolute;
  border-radius: 8px;
  bottom: 0;

  ${media.l} {
    // Used to get the underline bar a little below the
    // bottom of the parent element.
    bottom: -3px;
  }
`;
