import { createGlobalStyle } from 'styled-components';
import type { ProgrammeId } from '@/common/types';
import { ATRP, BAEC, IBP, AERC } from '@/utils/constants';

import { fontFamilies as AtrpFontFamilies } from './themes/atrp';
import { fontFamilies as BaecFontFamilies } from './themes/baec';
import { fontFamilies as IbpFontFamilies } from './themes/ibp';
import { fontFamilies as AercFontFamilies } from './themes/aerc';

const fontFamilies = {
  [ATRP]: AtrpFontFamilies,
  [BAEC]: BaecFontFamilies,
  [IBP]: IbpFontFamilies,
  [AERC]: AercFontFamilies,
};

const FontStyles = createGlobalStyle<{ programmeId: ProgrammeId }>`
   ${props => fontFamilies[props.programmeId]};
`;

export default FontStyles;
