import styled, { css } from 'styled-components';
import Icon from '@/components/Icon';
import { media } from '@/utils/viewports';

export const StyledIcon = styled(Icon)`
  stroke: #fff;
`;

export const UserInfoCompact = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.header.color};
  font-family: ${props => props.theme.header.fontFamily};
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.4rem;
`;

export const UserIcon = styled.div`
  position: relative;
  margin: 0 0.2rem;
`;

export const StyledUserIcon = styled(Icon)`
  fill: ${props => props.theme.header.userMenu.icons.userFill};
  stroke-width: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const LoggedInView = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2.8rem;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  margin: 0 1.2rem;

  ${media.l} {
    display: none;
  }
`;

export const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

export const UserImageContainer = styled.div`
  margin: 0 1.8rem 0 1.2rem;
  ${media.l} {
    padding-right: 1.2rem;
  }
`;

export const StyledCircleIcon = styled(Icon)`
  fill: ${props => props.theme.header.userMenu.icons.circleFill};
  stroke: ${props => props.theme.header.userMenu.icons.circleStroke};
  stroke-width: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const InfoBalance = styled.span`
  font-weight: 700;

  ${({ theme }) =>
    theme.header.userMenu.balance &&
    css`
      color: ${theme.header.userMenu.balance.color};
      font-size: ${theme.header.userMenu.balance.fontSize};
      font-weight: ${theme.header.userMenu.balance.fontWeight};
      font-family: ${theme.header.userMenu.balance.fontFamily};
      line-height: ${theme.header.userMenu.balance.lineHeight};
    `}
`;

export const ChevronContainer = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-size: inherit;
  color: inherit;
  cursor: pointer;
`;

export const StyledGreeting = styled.p`
  ${({ theme }) =>
    theme.header.userMenu.greeting &&
    css`
      color: ${theme.header.userMenu.greeting.color};
      font-size: ${theme.header.userMenu.greeting.fontSize};
      font-weight: ${theme.header.userMenu.greeting.fontWeight};
      font-family: ${theme.header.userMenu.greeting.fontFamily};
      line-height: ${theme.header.userMenu.greeting.lineHeight};
    `}
`;

export const InfoAvios = styled.span`
  ${({ theme }) =>
    theme.header.userMenu.avios &&
    css`
      color: ${theme.header.userMenu.avios.color};
      font-size: ${theme.header.userMenu.avios.fontSize};
      font-weight: ${theme.header.userMenu.avios.fontWeight};
      font-family: ${theme.header.userMenu.avios.fontFamily};
      line-height: ${theme.header.userMenu.avios.lineHeight};
    `}
`;
