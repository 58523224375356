import { css } from 'styled-components';
import type { DefaultTheme } from 'styled-components';
import { IBP } from '@/utils/constants';
import type { Font, Palette } from '../types';
import Iberia from '../fonts/iberia';

export const font: Font = {
  primary: 'Iberia Text',
  secondary: 'Iberia Headline',
};

export const fontFamilies = css`
  ${Iberia};

  body {
    font-family: ${font.primary}, ${font.secondary}, sans-serif;
  }
`;

export const primaryPalette: Palette = {
  red: {
    p100: '#FBE8EA',
    p200: '#F2B4BB',
    p300: '#E9818C',
    p400: '#E04D5C',
    p500: '#D7192D',
    p600: '#B71526',
    p700: '#971220',
    p800: '#760E19',
    p900: '#560A12',
  },
  yellow: {
    p100: '#FFFAE6',
    p200: '#FEF0AC',
    p300: '#FDE673',
    p400: '#FDDB39',
    p500: '#FCD100',
    p600: '#D0AC00',
    p700: '#A48800',
    p800: '#786300',
    p900: '#4C3F00',
  },
  grey: {
    p0: '#FFFFFF',
    p50: '#FAFAFA',
    p100: '#EEEEEE',
    p200: '#DDDDDD',
    p300: '#CCCCCC',
    p400: '#B3B3B3',
    p500: '#999999',
    p600: '#808080',
    p700: '#666666',
    p800: '#4D4D4D',
    p900: '#333333',
    p1: '#000000',
  },
};

export const secondaryPallete: Palette = {
  carmelian: {
    s100: '#FDE6E6',
    s500: '#A81A19',
  },
};

export const highlight: Palette = {
  red: '#D7192D',
  darkRed: '#A81A19',
};

export const iberiaGrey = '#2E2E2E';

const theme: DefaultTheme = {
  name: IBP,
  button: {
    background: primaryPalette.red.p500,
    borderColor: primaryPalette.red.p500,
    borderRadius: '2px',
    color: '#fff',
    disabled: primaryPalette.red.p200,
    fontFamily: font.primary,
    highlight: highlight.darkRed,
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
    textTransform: 'none',
  },
  card: {
    name: {
      fontFamily: font.primary,
      color: iberiaGrey,
      letterSpacing: '0.02rem',
      textTransform: 'none',
    },
    userId: {
      fontFamily: font.primary,
      color: primaryPalette.grey.p500,
      fontSize: '1.2rem',
      lineHeight: '2rem',
      letterSpacing: '0.06rem',
    },
    balance: {
      wrapperBackground: primaryPalette.red.p100,
      color: primaryPalette.red.p500,
      fontFamily: font.primary,
      fontSize: '1.6rem',
    },
    unlink: {
      color: primaryPalette.grey.p800,
      fontFamily: font.primary,
      fontSize: '1.4rem',
      fontWeight: '400',
      lineHeight: '2.1rem',
      unlinkStroke: primaryPalette.grey.p700,
      unlinkHoverStroke: '#A81A19',
    },
    iconStroke: primaryPalette.red.p500,
  },
  login: {
    containerBackground: primaryPalette.grey.p50,
    margin: '7rem 0 7rem 2.4rem',
    title: {
      color: iberiaGrey,
      fontFamily: font.secondary,
      fontSize: '5rem',
      fontWeight: '400',
      lineHeight: '5.8rem',
      textTransform: 'none',
    },
    subtitle: {
      color: iberiaGrey,
      fontFamily: font.secondary,
      fontSize: '2.4rem',
      fontWeight: '400',
      lineHeight: '3.2rem',
      textTransform: 'none',
    },
    description: {
      fontFamily: font.primary,
      fontSize: '1.6rem',
      color: primaryPalette.grey.p600,
      lineHeight: '2.2rem',
      fontWeight: '300',
    },
    button: {
      fontWeight: '400',
      alignment: 'center',
    },
    row: {
      gap: '2rem',
      tabletGap: '2rem',
      marginRight: '4rem',
    },
    partners: {
      mobileMargin: '0 1.9rem 5rem 0',
    },
  },
  loginPartners: {
    title: {
      fontFamily: font.primary,
      color: primaryPalette.grey.p600,
      fontWeight: 'normal',
      lineHeight: '1.8rem',
      fontSize: '1.4rem',
    },
  },
  logout: {
    fontFamily: font.primary,
    color: primaryPalette.grey.p600,
    fontSize: '1.6rem',
  },
  sidebar: {
    menuBackground: primaryPalette.grey.p50,
    listItem: {
      color: iberiaGrey,
      colorHighlight: primaryPalette.red.p500,
      fontFamily: font.primary,
      fontSize: '1.8rem',
      textTransform: 'none',
      fontWeight: '400',
      lineHeight: '2.7rem',
    },
    link: {
      colorHover: primaryPalette.red.p500,
    },
  },
  singleAviosBalance: {
    label: {
      fontFamily: font.primary,
      fontSize: '1.4rem',
      fontWeight: '700',
      lineHeight: '2.1rem',
      textTransform: 'uppercase',
      letterSpacing: '0.06rem',
      mobile: {
        fontSize: '1.4rem',
        lineHeight: '1.65rem',
      },
    },
    balance: {
      fontFamily: font.secondary,
      fontSize: '4.2rem',
      fontWeight: '400',
      lineHeight: '5.25rem',
      mobile: {
        fontSize: '3.4rem',
        lineHeight: '5.1rem',
      },
    },
    contentGap: '0',
  },
  spinner: {
    labelColor: primaryPalette.grey.p700,
    borderColor: primaryPalette.grey.p50,
    borderTopColor: primaryPalette.red.p500,
  },
  subHeader: {
    borderColor: primaryPalette.grey.p300,
    titleColor: primaryPalette.grey.p800,
    fontFamily: font.secondary,
    fontWeight: '400',
    fontSize: '3.4rem',
    margin: '1.5rem 0 0',
    titlePadding: '0 0 1.6rem',
    textTransform: 'none',
    lineHeight: '5.1rem',
    mobile: {
      fontSize: '2.4rem',
      lineHeight: '3.6rem',
    },
  },
  tooltip: {
    fontFamily: 'Verdana',
    color: primaryPalette.grey.p600,
    fontSize: '1.4rem',
    title: {
      fontFamily: 'Verdana',
      fontWeight: '700',
      color: primaryPalette.grey.p800,
      fontSize: '1.6rem',
      letterSpacing: '0',
      textTransform: 'none',
    },
  },
  walletView: {
    containerBackground: primaryPalette.grey.p50,
    header: {
      fontFamily: font.secondary,
      color: iberiaGrey,
      fontSize: '3.4rem',
      fontWeight: '400',
      lineHeight: '5.1rem',
      textTransform: 'none',
    },
    subtitle: {
      fontFamily: 'Verdana',
      color: primaryPalette.grey.p600,
      fontSize: '1.4rem',
      fontWeight: 'normal',
      lineHeight: '2.1rem',
      mobile: {
        fontSize: '1.2rem',
        lineHeight: '1.8rem',
      },
    },
    rules: {
      fontWeight: '700',
    },
    latestTransactions: {
      headline: {
        fontFamily: font.primary,
        color: primaryPalette.grey.p800,
        fontWeight: '700',
        fontSize: '1.4rem',
        letterSpacing: '0.06rem',
        mobile: {
          fontSize: '1.1rem',
        },
      },
      extraAction: {
        fontFamily: 'Verdana',
        color: highlight.red,
        fontSize: '1.2rem',
        mobile: {
          fontSize: '1rem',
        },
      },
      description: {
        fontFamily: 'Verdana',
        color: primaryPalette.grey.p800,
        fontWeight: 'normal',
        fontSize: '1.4rem',
      },
      amount: {
        fontFamily: font.primary,
        color: iberiaGrey,
        fontSize: '1.6rem',
        fontWeight: 'normal',
        lineHeight: '2.4rem',
      },
      emptyTransactions: {
        color: primaryPalette.grey.p800,
        fontSize: '1.4rem',
        fontFamily: 'Verdana',
      },
      errorTransactions: {
        color: '#E8111C',
        fontFamily: font.secondary,
        fontSize: '1.2rem',
        lineHeight: '1.8rem',
      },
    },
  },
  toaster: {
    error: {
      background: secondaryPallete.carmelian.s100,
      color: secondaryPallete.carmelian.s500,
      borderLeft: `4px solid ${secondaryPallete.carmelian.s500}`,
    },
    success: {
      background: '#E5F2F1',
      color: '#008673',
      borderLeft: '4px solid #008673',
    },
    pending: {
      background: '#F08400',
      color: primaryPalette.grey.p0,
      borderLeft: '4px solid #F08400',
    },
  },
  popup: {
    confirmation: {
      title: {
        fontFamily: font.secondary,
        textTransform: 'none',
      },
      description: {
        fontFamily: font.primary,
        color: primaryPalette.grey.p700,
      },
      cancelButton: {
        borderColor: primaryPalette.red.p500,
        color: primaryPalette.red.p500,
        backgroundColor: '#fff',
        borderWidth: '1px',
      },
      successButton: {
        textTransform: 'none',
      },
    },
    link: {
      fontFamily: 'Verdana',
    },
    unlinkIconStrike: primaryPalette.red.p500,
  },
  latestTransactions: {
    containerBackground: primaryPalette.grey.p50,
    header: {
      fontFamily: font.secondary,
      color: primaryPalette.grey.p800,
      fontSize: '3.4rem',
      fontWeight: '300',
    },
    filterTitle: {
      fontFamily: font.primary,
      color: primaryPalette.grey.p800,
      fontWeight: '700',
      fontSize: '1.2rem',
      lineHeight: '1.8rem',
    },
    subtitle: {
      fontFamily: 'Verdana',
      color: primaryPalette.grey.p600,
      fontSize: '1.4rem',
      fontWeight: 'normal',
      lineHeight: '2.2rem',
    },
    table: {
      header: {
        background: primaryPalette.red.p100,
        fontFamily: 'Verdana',
        fontWeight: '700',
        color: primaryPalette.grey.p800,
        fontSize: '1.2rem',
      },
      item: {
        color: primaryPalette.grey.p800,
        background: primaryPalette.grey.p50,
        fontFamily: 'Verdana',
        amountColor: primaryPalette.grey.p800,
        fontSize: '1.4rem',
        lineHeight: '1.8rem',
      },
      error: {
        fontFamily: font.primary,
        fontSize: '1.6rem',
        color: highlight.red,
      },
      empty: {
        fontFamily: font.primary,
        fontSize: '1.6rem',
        color: primaryPalette.grey.p800,
      },
    },
    pagination: {
      activeColor: primaryPalette.grey.p900,
      disabledColor: primaryPalette.grey.p500,
    },
  },
  dropdown: {
    fontFamily: font.primary,
    color: primaryPalette.grey.p600,
    borderColor: primaryPalette.grey.p900,
    labelColor: primaryPalette.grey.p800,
    hintColor: primaryPalette.grey.p500,
    hoverBackground: primaryPalette.grey.p100,
    titleFontWeight: '400',
    menuListHeight: '20rem',
    selected: {
      color: primaryPalette.grey.p800,
      iconColor: primaryPalette.red.p500,
    },
  },
  dateSelector: {
    borderColor: primaryPalette.grey.p900,
    color: primaryPalette.grey.p900,
    fontFamily: font.primary,
    label: {
      color: primaryPalette.grey.p900,
      fontFamily: 'Verdana',
      fontWeight: '400',
    },
    calendar: {
      selectedColor: primaryPalette.red.p500,
      dayName: {
        color: primaryPalette.grey.p500,
      },
      today: {
        color: primaryPalette.grey.p100,
      },
    },
  },
  footer: {
    background: primaryPalette.red.p500,
    color: primaryPalette.grey.p0,
    fontFamily: font.primary,
    colorHover: '#FCD100',
  },
  header: {
    background: primaryPalette.red.p500,
    color: primaryPalette.grey.p0,
    fontFamily: 'Verdana',
    isCentered: false,
    borderBottom: '1px solid #f1f1f2',
    boxShadow: 'none',
    userIcon: {
      stroke: primaryPalette.red.p300,
      fill: primaryPalette.red.p700,
    },
    userMenu: {
      title: {
        fontFamily: 'Verdana',
        fontSize: '1.2rem',
        lineHeight: '1.8rem',
        color: primaryPalette.grey.p700,
        letterSpacing: '0.02rem',
      },
      text: {
        fontFamily: font.secondary,
        fontSize: '2.4rem',
        fontWeight: '400',
        lineHeight: '3.6rem',
        color: iberiaGrey,
      },
      link: {
        fontFamily: font.primary,
        fontWeight: '400',
        color: iberiaGrey,
        letterSpacing: '0.02rem',
      },
      icons: {
        userFill: '#b8b8b8',
        circleFill: '#414141',
        circleStroke: '#b8b8b8',
      },
      greeting: {
        color: '#fff',
        fontFamily: 'Verdana',
        fontSize: '1.3rem',
        fontWeight: '400',
        lineHeight: 'normal',
      },
      balance: {
        color: '#fff',
        fontFamily: 'Verdana',
        fontSize: '1.1rem',
        fontWeight: '400',
        lineHeight: 'normal',
      },
      avios: {
        color: '#fff',
        fontFamily: 'Verdana',
        fontSize: '1.1rem',
        fontWeight: '400',
        lineHeight: 'normal',
      },
      aviosBalance: {
        fontFamily: 'Verdana',
        fontSize: '2rem',
        fontWeight: '400',
        lineHeight: '3rem',
        color: iberiaGrey,
      },
      myAccount: {
        background: '#f8f8f8',
      },
      logout: {
        background: '#f8f8f8',
      },
      errorColor: highlight.red,
      popupRightPosition: '8rem',
      expiryDateColor: primaryPalette.grey.p700,
    },
    rightBlock: {
      mobileDisplay: 'inherit',
    },
  },
  languageSelector: {
    container: {
      fontFamily: 'Verdana',
      fontSize: '1.4rem',
      lineHeight: '2rem',
      fontWeight: '400',
      letterSpacing: '0',
      color: '#fff',
      padding: '0.5rem 1.6rem 0.5rem 0.5rem',
      tabletDisplay: 'none',
    },
    title: {
      fontFamily: 'Verdana',
      fontSize: '1.4rem',
      color: primaryPalette.grey.p900,
      lineHeight: '2.1rem',
    },
    button: {
      fontSize: '1.8rem',
      fontWeight: '400',
    },
    selectDescription: {
      color: '#fff',
      fontFamily: 'Verdana',
      fontSize: '1.3rem',
      fontWeight: '400',
      lineHeight: '1.6rem',
    },
    chevronStroke: '#fff',
  },
  help: {
    arrowColor: primaryPalette.red.p500,
    background: primaryPalette.grey.p50,
    fontFamilyTitle: font.secondary,
    fontFamilyText: 'Verdana',
    titleColor: primaryPalette.grey.p900,
    textColor: primaryPalette.grey.p700,
    textTransformTitle: 'none',
    fontSizeTitle: '3.4rem',
    fontWeightTitle: '300',
    lineHeightTitle: '5.1rem',
    fontSizeText: '1.2rem',
    fontWeightText: '300',
    helpMenu: {
      border: `2px solid ${primaryPalette.red.p500}`,
      hoverBorderColor: `2px solid ${primaryPalette.red.p200}`,
    },
  },
  contactUs: {
    title: {
      color: primaryPalette.grey.p900,
      fontFamily: font.secondary,
      fontSize: '3.4rem',
      fontWeight: '400',
      lineHeight: '5.1rem',
    },
    description: {
      color: primaryPalette.grey.p700,
      fontFamily: 'Verdana',
      fontSize: '1.2rem',
      lineHeight: '1.8rem',
    },
    formTitle: {
      color: iberiaGrey,
      fontFamily: font.secondary,
      fontSize: '2.4rem',
      fontWeight: '400',
      lineHeight: '3.6rem',
    },
    input: {
      borderColor: primaryPalette.grey.p600,
      textColor: primaryPalette.grey.p600,
      textFontFamily: 'Verdana',
    },
    textAreaDescription: {
      color: primaryPalette.grey.p700,
      fontFamily: 'Verdana',
      fontSize: '1.4rem',
    },
    textAreaCount: {
      color: primaryPalette.grey.p500,
      fontFamily: 'Verdana',
      fontSize: '1.2rem',
      fontWeight: '400',
    },
    submissionResult: {
      title: {
        color: iberiaGrey,
        errorColor: primaryPalette.red.p800,
        fontFamily: font.secondary,
        fontSize: '3.4rem',
        fontSizeTablet: '3.4rem',
        fontSizeMobile: '3.4rem',
        fontWeight: '400',
        lineHeight: '5.1rem',
        lineHeightTablet: '5.1rem',
        lineHeightMobile: '5.1rem',
        letterSpacing: '0',
        textTransform: 'none',
      },
      description: {
        color: primaryPalette.grey.p700,
        fontFamily: 'Verdana',
        fontSize: '1.4rem',
        fontWeight: '400',
        lineHeight: '2.1rem',
      },
    },
  },
};

export default theme;
