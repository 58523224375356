import { css } from 'styled-components';

import DiodrumLightWoff2 from '@/public/fonts/Diodrum/Diodrum-Light.woff2';
import DiodrumLightWoff from '@/public/fonts/Diodrum/Diodrum-Light.woff';

import DiodrumRegularWoff2 from '@/public/fonts/Diodrum/Diodrum-Regular.woff2';
import DiodrumRegularWoff from '@/public/fonts/Diodrum/Diodrum-Regular.woff';

import DiodrumMediumWoff2 from '@/public/fonts/Diodrum/Diodrum-Medium.woff2';
import DiodrumMediumWoff from '@/public/fonts/Diodrum/Diodrum-Medium.woff';

import DiodrumSemiboldWoff2 from '@/public/fonts/Diodrum/Diodrum-Semibold.woff2';
import DiodrumSemiboldWoff from '@/public/fonts/Diodrum/Diodrum-Semibold.woff';

const fonts = css`
  @font-face {
    font-family: 'Diodrum';
    font-style: normal;
    font-weight: 300;
    src: url(${DiodrumLightWoff2}) format('woff2'),
      url(${DiodrumLightWoff}) format('woff');
  }

  @font-face {
    font-family: 'Diodrum';
    font-style: normal;
    font-weight: normal;
    src: url(${DiodrumRegularWoff2}) format('woff2'),
      url(${DiodrumRegularWoff}) format('woff');
  }

  @font-face {
    font-family: 'Diodrum';
    font-style: normal;
    font-weight: 500;
    src: url(${DiodrumMediumWoff2}) format('woff2'),
      url(${DiodrumMediumWoff}) format('woff');
  }

  @font-face {
    font-family: 'Diodrum';
    font-style: normal;
    font-weight: 600;
    src: url(${DiodrumSemiboldWoff2}) format('woff2'),
      url(${DiodrumSemiboldWoff}) format('woff');
  }
`;

export default fonts;
