import { css } from 'styled-components';

import IberiaTextRegularWoff2 from '@/public/fonts/Iberia/IberiaText-Regular.woff2';
import IberiaTextRegularWoff from '@/public/fonts/Iberia/IberiaText-Regular.woff';

import IberiaTextBoldWoff2 from '@/public/fonts/Iberia/IberiaText-Bold.woff2';
import IberiaTextBoldWoff from '@/public/fonts/Iberia/IberiaText-Bold.woff';

import IberiaHeadlineWoff2 from '@/public/fonts/Iberia/Iberia-Headline.woff2';
import IberiaHeadlineWoff from '@/public/fonts/Iberia/Iberia-Headline.woff';

const fonts = css`
  @font-face {
    font-family: 'Iberia Text';
    font-style: normal;
    font-weight: normal;
    src: url(${IberiaTextRegularWoff2}) format('woff2'),
      url(${IberiaTextRegularWoff}) format('woff');
  }

  @font-face {
    font-family: 'Iberia Text';
    font-style: normal;
    font-weight: bold;
    src: url(${IberiaTextBoldWoff2}) format('woff2'),
      url(${IberiaTextBoldWoff}) format('woff');
  }

  @font-face {
    font-family: 'Iberia Headline';
    font-style: normal;
    font-weight: normal;
    src: url(${IberiaHeadlineWoff2}) format('woff2'),
      url(${IberiaHeadlineWoff}) format('woff');
  }
`;

export default fonts;
