import { media } from '@/utils/viewports';
import Link from 'next/link';
import styled from 'styled-components';
import Icon from '@/components/Icon';

export const Wrapper = styled.div`
  margin-left: 3.2rem;
`;

export const InnerWrapper = styled(Link)`
  align-items: center;
  color: white;
  cursor: pointer;
  display: flex;
  gap: 0.8rem;
  text-decoration: none;
`;

export const StyledSpan = styled.span`
  font-family: Verdana;
  font-size: 1.3rem;
  line-height: 1.6rem;

  ${media.l} {
    display: none;
  }
`;

export const StyledIcon = styled(Icon)`
  height: 2.4rem;
  width: 2.4rem;
  border-left: 1px solid white;
  padding-left: 3.2rem;

  ${media.s} {
    height: 1.6rem;
    width: 1.6rem;
  }

  ${media.l} {
    border-left: unset;
  }
`;
