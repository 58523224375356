import React, { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from '@/utils/useTranslation';
import {
  SubHeaderContainer,
  SubHeaderTitle,
  HeaderSeparator,
} from './styledComponents';
import { setCurrentPage } from '@/utils/setCurrentPage';
import type { ProgrammeId } from '@/common/types';
import { useGlobalContext } from '../GlobalProvider';

const getTranslationsPath = (programmeId: ProgrammeId, currentPage: string) => {
  switch (currentPage) {
    case 'header':
      return currentPage;
    case 'contact-us':
      return `${programmeId}/faqs`;
    default:
      return `${programmeId}/${currentPage}`;
  }
};

const SubHeader = () => {
  const router = useRouter();
  const { programmeId = 'BAEC' } = useGlobalContext();

  const currentPage = setCurrentPage(router.pathname);

  const { t } = useTranslation(getTranslationsPath(programmeId, currentPage));

  const subHeaderTitle = useCallback(
    (currentPage: string): string => {
      if (currentPage === 'header') {
        return t('subHeader.title');
      } else if (currentPage === 'faqs' || currentPage === 'contact-us') {
        return t('subtitle');
      } else {
        return t('title');
      }
    },
    [t, currentPage],
  );

  if (
    (programmeId === 'ATRP' || programmeId === 'IBP') &&
    router.pathname === '/'
  )
    return <HeaderSeparator />;

  return (
    <SubHeaderContainer>
      <SubHeaderTitle>{subHeaderTitle(currentPage)}</SubHeaderTitle>
    </SubHeaderContainer>
  );
};

export default SubHeader;
