import { css } from 'styled-components';

import OpenSansLightWoff2 from '@/public/fonts/OpenSans/OpenSans-Light.woff2';
import OpenSansLightWoff from '@/public/fonts/OpenSans/OpenSans-Light.woff';

import OpenSansRegularWoff2 from '@/public/fonts/OpenSans/OpenSans-Regular.woff2';
import OpenSansRegularWoff from '@/public/fonts/OpenSans/OpenSans-Regular.woff';

import OpenSansBoldWoff2 from '@/public/fonts/OpenSans/OpenSans-Bold.woff2';
import OpenSansBoldWoff from '@/public/fonts/OpenSans/OpenSans-Bold.woff';

const fonts = css`
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url(${OpenSansLightWoff2}) format('woff2'),
      url(${OpenSansLightWoff}) format('woff');
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    src: url(${OpenSansRegularWoff2}) format('woff2'),
      url(${OpenSansRegularWoff}) format('woff');
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    src: url(${OpenSansBoldWoff2}) format('woff2'),
      url(${OpenSansBoldWoff}) format('woff');
  }
`;

export default fonts;
