import { css } from 'styled-components';
import type { DefaultTheme } from 'styled-components';
import { BAEC } from '@/utils/constants';
import type { Font, Palette } from '../types';
import MyliusModern from '../fonts/myliusModern';
import OpenSans from '../fonts/openSans';

export const font: Font = {
  primary: 'Mylius Modern',
  secondary: 'Open Sans',
};

export const fontFamilies = css`
  ${MyliusModern};
  ${OpenSans};

  body {
    font-family: ${font.primary}, ${font.secondary}, sans-serif;
  }
`;

export const primaryPalette: Palette = {
  blue: {
    p50: '#EAEFF5',
    p100: '#C5D1E2',
    p200: '#9FB4D0',
    p300: '#7997BE',
    p400: '#5479AB',
    p500: '#2E5C99',
    p600: '#234C83',
    p700: '#183C6D',
    p800: '#0D2B57',
    p900: '#021B41',
  },
  red: {
    p50: '#FAE9E7',
    p100: '#F1C1BC',
    p200: '#E99991',
    p300: '#E07165',
    p400: '#D7493A',
    p500: '#CE210F',
    p600: '#AF1C0D',
    p700: '#90170B',
    p800: '#711208',
    p900: '#520D06',
  },
  neutral: {
    p0: '#FFFFFF',
    p50: '#FAFAFA',
    p100: '#ECECED',
    p200: '#D8D9DC',
    p300: '#B6B7BD',
    p400: '#94959E',
    p500: '#71737F',
    p600: '#60626C',
    p700: '#4F5159',
    p800: '#3E3F46',
    p900: '#2D2E33',
    p1: '#000000',
  },
};

export const secondaryPallete = {
  green: {
    s100: '#E6F2EE',
    s500: '#008058',
  },
  orange: {
    s100: '#FCEFE8',
    s500: '#DF6014',
  },
};

export const highlight: Palette = {
  blue: '#2671D3',
  darkBlue: '#2E5C99',
};

const theme: DefaultTheme = {
  name: BAEC,
  button: {
    background: primaryPalette.blue.p500,
    borderColor: primaryPalette.blue.p500,
    borderRadius: '2px',
    color: '#fff',
    disabled: primaryPalette.blue.p200,
    fontFamily: font.secondary,
    highlight: highlight.blue,
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
    textTransform: 'none',
  },
  card: {
    name: {
      fontFamily: font.secondary,
      color: primaryPalette.blue.p900,
      letterSpacing: '0.02rem',
      textTransform: 'none',
    },
    userId: {
      fontFamily: font.primary,
      color: primaryPalette.neutral.p500,
      fontSize: '1.2rem',
      lineHeight: '2rem',
      letterSpacing: '0.06rem',
    },
    balance: {
      wrapperBackground: primaryPalette.neutral.p50,
      color: primaryPalette.blue.p500,
      fontFamily: font.primary,
      fontSize: '1.6rem',
    },
    unlink: {
      color: primaryPalette.blue.p500,
      fontFamily: font.secondary,
      fontSize: '1.2rem',
      fontWeight: '400',
      lineHeight: '1.8rem',
      unlinkStroke: primaryPalette.blue.p500,
      unlinkHoverStroke: '#E8111C',
    },
    iconStroke: primaryPalette.blue.p500,
  },
  login: {
    containerBackground: primaryPalette.neutral.p50,
    margin: '7rem 0 7rem 2.4rem',
    title: {
      color: primaryPalette.blue.p900,
      fontFamily: font.primary,
      fontSize: '4.6rem',
      fontWeight: '300',
      lineHeight: '5.4rem',
      textTransform: 'none',
    },
    subtitle: {
      color: primaryPalette.blue.p900,
      fontFamily: font.primary,
      fontSize: '2rem',
      fontWeight: '300',
      lineHeight: '2.8rem',
      textTransform: 'none',
    },
    description: {
      fontFamily: font.secondary,
      fontSize: '1.4rem',
      color: primaryPalette.neutral.p500,
      lineHeight: '2.2rem',
      fontWeight: '300',
    },
    button: {
      fontWeight: '400',
      alignment: 'center',
    },
    row: {
      gap: '2rem',
      tabletGap: '2rem',
      marginRight: '4rem',
    },
  },
  loginPartners: {
    title: {
      fontFamily: font.secondary,
      color: primaryPalette.neutral.p500,
      fontWeight: 'normal',
      lineHeight: '1.8rem',
      fontSize: '1.4rem',
    },
  },
  logout: {
    fontFamily: font.primary,
    color: '#71737f',
    fontSize: '1.6rem',
  },
  sidebar: {
    menuBackground: primaryPalette.neutral.p50,
    listItem: {
      color: primaryPalette.blue.p900,
      colorHighlight: primaryPalette.blue.p500,
      fontFamily: font.secondary,
      fontSize: '1.4rem',
      textTransform: 'none',
      fontWeight: '400',
      lineHeight: '2.2rem',
    },
    link: {
      colorHover: primaryPalette.blue.p500,
    },
  },
  singleAviosBalance: {
    label: {
      fontFamily: font.primary,
      fontSize: '1.2rem',
      fontWeight: '700',
      lineHeight: '2rem',
      textTransform: 'uppercase',
      letterSpacing: '0.1rem',
      mobile: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
    },
    balance: {
      fontFamily: font.primary,
      fontSize: '3.8rem',
      fontWeight: '300',
      lineHeight: '4.6rem',
      mobile: {
        fontSize: '3rem',
        lineHeight: '4.5rem',
      },
    },
    contentGap: '1rem',
  },
  spinner: {
    labelColor: primaryPalette.neutral.p500,
    borderColor: primaryPalette.neutral.p50,
    borderTopColor: primaryPalette.blue.p500,
  },
  subHeader: {
    borderColor: primaryPalette.neutral.p100,
    titleColor: primaryPalette.blue.p900,
    fontFamily: font.primary,
    fontWeight: '300',
    fontSize: '3rem',
    margin: '1.5rem 0 0',
    titlePadding: '0 0 2rem',
    textTransform: 'none',
    lineHeight: '3.8rem',
    mobile: {
      fontSize: '2rem',
      lineHeight: '2.8rem',
    },
  },
  tooltip: {
    fontFamily: font.secondary,
    color: primaryPalette.neutral.p500,
    fontSize: '1.2rem',
    title: {
      fontFamily: font.secondary,
      fontWeight: '600',
      color: primaryPalette.blue.p900,
      fontSize: '1.4rem',
      letterSpacing: '0.02rem',
      textTransform: 'none',
    },
  },
  walletView: {
    containerBackground: primaryPalette.neutral.p50,
    header: {
      fontFamily: font.primary,
      color: primaryPalette.blue.p900,
      fontSize: '3rem',
      fontWeight: '300',
      lineHeight: '3.8rem',
      textTransform: 'none',
    },
    subtitle: {
      fontFamily: font.secondary,
      color: primaryPalette.neutral.p500,
      fontSize: '1.4rem',
      fontWeight: 'normal',
      lineHeight: '2.2rem',
      mobile: {
        fontSize: '1.2rem',
        lineHeight: '1.8rem',
      },
    },
    latestTransactions: {
      headline: {
        fontFamily: font.primary,
        color: primaryPalette.blue.p500,
        fontWeight: '700',
        fontSize: '1.2rem',
        letterSpacing: '0.1rem',
        mobile: {
          fontSize: '1rem',
        },
      },
      extraAction: {
        fontFamily: font.secondary,
        color: highlight.darkBlue,
        fontSize: '1.2rem',
        mobile: {
          fontSize: '1rem',
        },
      },
      description: {
        fontFamily: font.secondary,
        color: primaryPalette.blue.p500,
        fontWeight: 'normal',
        fontSize: '1.2rem',
      },
      amount: {
        fontFamily: font.primary,
        color: primaryPalette.blue.p900,
        fontSize: '1.6rem',
        fontWeight: 'normal',
        lineHeight: '2.4rem',
      },
      emptyTransactions: {
        color: primaryPalette.neutral.p500,
        fontFamily: font.secondary,
        fontSize: '1.4rem',
      },
      errorTransactions: {
        color: '#E8111C',
        fontFamily: font.secondary,
        fontSize: '1.2rem',
        lineHeight: '1.8rem',
      },
    },
  },
  toaster: {
    error: {
      background: '#FAE3E3',
      color: '#CF2425',
      borderLeft: '4px solid #CF2425',
    },
    success: {
      background: secondaryPallete.green.s100,
      color: secondaryPallete.green.s500,
      borderLeft: `4px solid ${secondaryPallete.green.s500}`,
    },
    pending: {
      background: secondaryPallete.orange.s500,
      color: '#FEFEFE',
      borderLeft: `4px solid ${secondaryPallete.orange.s500}`,
    },
  },
  popup: {
    confirmation: {
      title: {
        fontFamily: font.primary,
        textTransform: 'none',
      },
      description: {
        fontFamily: font.secondary,
        color: primaryPalette.neutral.p500,
      },
      cancelButton: {
        borderColor: primaryPalette.blue.p500,
        color: primaryPalette.blue.p500,
        backgroundColor: '#fff',
        borderWidth: '2px',
      },
      successButton: {
        textTransform: 'none',
      },
    },
    link: {
      fontFamily: font.secondary,
    },
    unlinkIconStrike: '#E8111C',
  },
  latestTransactions: {
    containerBackground: primaryPalette.neutral.p50,
    header: {
      fontFamily: font.primary,
      color: primaryPalette.blue.p900,
      fontSize: '3rem',
      fontWeight: '300',
    },
    filterTitle: {
      fontFamily: font.primary,
      color: primaryPalette.blue.p800,
      fontWeight: '700',
      fontSize: '1.2rem',
      lineHeight: '1.8rem',
    },
    subtitle: {
      fontFamily: font.secondary,
      color: primaryPalette.neutral.p500,
      fontSize: '1.4rem',
      fontWeight: 'normal',
      lineHeight: '2.2rem',
    },
    table: {
      header: {
        background: primaryPalette.blue.p50,
        fontFamily: font.secondary,
        fontWeight: '400',
        color: primaryPalette.blue.p900,
        fontSize: '1.4rem',
      },
      item: {
        color: primaryPalette.neutral.p600,
        background: primaryPalette.neutral.p50,
        fontFamily: font.secondary,
        amountColor: primaryPalette.neutral.p800,
        fontSize: '1.4rem',
        lineHeight: '1.8rem',
      },
      error: {
        fontFamily: font.secondary,
        fontSize: '1.4rem',
        color: '#E8111C',
      },
      empty: {
        fontFamily: font.secondary,
        fontSize: '1.4rem',
        color: primaryPalette.neutral.p800,
      },
    },
    pagination: {
      activeColor: primaryPalette.blue.p500,
      disabledColor: primaryPalette.blue.p200,
    },
  },
  dropdown: {
    fontFamily: font.primary,
    color: primaryPalette.blue.p500,
    borderColor: primaryPalette.blue.p900,
    labelColor: primaryPalette.blue.p500,
    hintColor: primaryPalette.neutral.p500,
    hoverBackground: primaryPalette.neutral.p100,
    titleFontWeight: '400',
    menuListHeight: '20rem',
    selected: {
      color: primaryPalette.blue.p900,
      iconColor: primaryPalette.blue.p500,
    },
  },
  dateSelector: {
    borderColor: primaryPalette.blue.p900,
    color: primaryPalette.blue.p900,
    fontFamily: font.primary,
    label: {
      color: primaryPalette.blue.p500,
      fontFamily: font.secondary,
      fontWeight: '400',
    },
    calendar: {
      selectedColor: primaryPalette.blue.p500,
      dayName: {
        color: primaryPalette.neutral.p500,
      },
      today: {
        color: primaryPalette.neutral.p100,
      },
    },
  },
  footer: {
    background: primaryPalette.blue.p900,
    color: primaryPalette.neutral.p0,
    fontFamily: font.secondary,
  },
  header: {
    background: primaryPalette.blue.p500,
    color: primaryPalette.neutral.p0,
    fontFamily: font.secondary,
    isCentered: false,
    borderBottom: '1px solid #f1f1f2',
    boxShadow: 'none',
    userIcon: {
      stroke: primaryPalette.blue.p300,
      fill: primaryPalette.blue.p700,
    },
    userMenu: {
      title: {
        fontFamily: font.secondary,
        fontSize: '1.4rem',
        lineHeight: '2.2rem',
        color: primaryPalette.neutral.p500,
        letterSpacing: '0',
      },
      text: {
        fontFamily: font.primary,
        fontSize: '2rem',
        fontWeight: '300',
        lineHeight: '2.8rem',
        color: primaryPalette.blue.p900,
      },
      link: {
        fontFamily: font.secondary,
        fontWeight: '400',
        color: primaryPalette.blue.p500,
        letterSpacing: '0',
      },
      icons: {
        userFill: '#b8b8b8',
        circleFill: '#414141',
        circleStroke: '#b8b8b8',
      },
      aviosBalance: {
        fontFamily: font.primary,
        fontSize: '2rem',
        fontWeight: '300',
        lineHeight: '2.8rem',
        color: primaryPalette.blue.p900,
      },
      myAccount: {
        background: '#f8f8f8',
      },
      logout: {
        background: '#f8f8f8',
      },
      errorColor: '#E8111C',
      popupRightPosition: '-0.8rem',
      expiryDateColor: primaryPalette.neutral.p600,
    },
    rightBlock: {
      mobileDisplay: 'none',
    },
  },
  languageSelector: {
    container: {
      fontFamily: font.secondary,
      fontSize: '1.4rem',
      lineHeight: '2.2rem',
      fontWeight: '600',
      letterSpacing: '0.02rem',
      color: '#fff',
      padding: '0.5rem',
      tabletDisplay: 'inherit',
    },
    title: {
      fontFamily: font.secondary,
      fontSize: '1.6rem',
      color: primaryPalette.blue.p900,
      lineHeight: '2.4rem',
    },
    button: {
      fontSize: '1.8rem',
      fontWeight: '400',
    },
    chevronStroke: '#fff',
  },
  help: {
    arrowColor: primaryPalette.blue.p500,
    background: primaryPalette.neutral.p50,
    fontFamilyTitle: font.primary,
    fontFamilyText: font.primary,
    titleColor: primaryPalette.neutral.p900,
    textColor: primaryPalette.neutral.p700,
    textTransformTitle: 'none',
    fontSizeTitle: '3.4rem',
    fontWeightTitle: '300',
    lineHeightTitle: '5.1rem',
    fontSizeText: '1.2rem',
    fontWeightText: '300',
    helpMenu: {
      border: `2px solid ${primaryPalette.blue.p500}`,
      hoverBorderColor: `2px solid ${primaryPalette.blue.p200}`,
    },
  },
  contactUs: {
    title: {
      color: primaryPalette.blue.p900,
      fontFamily: font.primary,
      fontSize: '3rem',
      fontWeight: '300',
      lineHeight: '4.5rem',
    },
    description: {
      color: primaryPalette.neutral.p500,
      fontFamily: font.secondary,
      fontSize: '1.4rem',
      lineHeight: '2.1rem',
    },
    formTitle: {
      color: primaryPalette.blue.p900,
      fontFamily: font.primary,
      fontSize: '2rem',
      fontWeight: '300',
      lineHeight: '3rem',
    },
    input: {
      borderColor: primaryPalette.neutral.p400,
      textColor: primaryPalette.neutral.p500,
      textFontFamily: font.secondary,
    },
    textAreaDescription: {
      color: primaryPalette.neutral.p500,
      fontFamily: font.secondary,
      fontSize: '1.4rem',
    },
    textAreaCount: {
      color: primaryPalette.neutral.p500,
      fontFamily: font.secondary,
      fontSize: '1.2rem',
      fontWeight: '400',
    },
    submissionResult: {
      title: {
        color: '#0032A0',
        errorColor: '#B0251B',
        fontFamily: font.primary,
        fontSize: '2.8rem',
        fontSizeTablet: '2.6rem',
        fontSizeMobile: '2.4rem',
        fontWeight: '500',
        lineHeight: '3.6rem',
        lineHeightTablet: '3.4rem',
        lineHeightMobile: '3rem',
        letterSpacing: '-0.932px',
        textTransform: 'none',
      },
      description: {
        color: '#4B4855',
        fontFamily: font.secondary,
        fontSize: '1.6rem',
        fontWeight: '400',
        lineHeight: '2.8rem',
      },
    },
  },
};

export default theme;
